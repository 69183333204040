import React, { Component } from "react";
import { PropTypes } from "prop-types";
import "../App.css";

// Firebase
//import firebase from "./../util/config";

// Redux
import { connect } from "react-redux";
import { getQuestions } from "../redux/actions/dataAction";
import {
  addNote,
  getGroupInfo,
  createGroupChatbubble,
  getNotesReset,
} from "../redux/actions/userAction";
import { getGroupList, removeGroupList } from "../redux/actions/dataAction";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import IconButton from "@material-ui/core/IconButton";


// Components
import GroupTabs from "../Component/GroupTabs";
import UsersInChatTab from "../Component/UsersInChatTab";
import TextField from "@material-ui/core/TextField";
import { LoadingPage } from "./../util/LoadingPage";
import AskQuestion from "../Component/AskQuestion";

import Conversation from "../Component/Conversation";
import Notes from "./../Component/Notes";

// Import languages
import languageWords from "../Languages";

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    top: 55,
    left: 20,
    width: "100%",
    paddingRight: 40,
    height: "100%",
    paddingBottom: 225,
  },
  groupName: {
    fontSize: 18,
  },
  groupNameSmall: {
    fontSize: 18,
    marginTop: 10,
  },
  usersInChat: {
    position: "absolute",
    right: 40,
    marginTop: 38,
  },
  findNewGroup: {
    position: "absolute",
    right: 120,
    marginTop: 35,
  },
  chatBorder: {
    border: "1px solid black",
    width: "100%",
    height: "100%",
    position: "relative",
    overflowY: "scroll",
    marginBottom: 10,
    backgroundColor: theme.palette.backgroundColor,
  },
  chatBar: {
    position: "relative",
    left: 0,
    width: "95%",
    marginRight: 10,
  },
  sendButton: {
    width: 35,
    height: 35,
    fill: "green",
  },
  welcome: {
    marginTop: 50,
    margin: "auto",
    zIndex: -1,
    marginLeft: 20,
    marginRight: 20,
  },
  chatList: {
    textAlign: "left",
    listStyleType: "none",
    margin: 0,
    paddingLeft: 0,
    fontSize: 18,
    width: "100%",
    wordWrap: "breakWord",
    flexWrap: "wrap",
    overflowWrap: "anywhere",
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      showPopup: false,
      isDesktop: false,
      currentUsers: null,
      chats: [],
      readError: null,
    };

    this.updatePredicate = this.updatePredicate.bind(this);
    this.handleGroup = this.handleGroup.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    this.props.removeGroupList();
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 479 });
  }

  handleSumbit = (event) => {
    // event.preventDefault();
    const noteData = {
      noteData: this.state.note,
    };

    if (this.props.currentGroup.id !== "notes") {
      const messageSend = {
        id: this.props.currentGroup.id,
        message: this.state.note,
      };
      this.props.createGroupChatbubble(messageSend);
    } else {
      this.props.addNote(noteData);
    }
    this.setState({ note: "" });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleGroup(event) {
    if (event === "notes") {
      this.setState({ groupsTab: event });
      this.props.getNotesReset();
      // console.log(this.state.groupsTab)
    } else {
      this.props.getGroupInfo(event, this.props.history);
      this.setState({ groupsTab: event });
      // console.log(this.state.groupsTab)
    }
  }
  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }
  // keydown(event) {
  //   alert("test");
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     this.onSubmit();
  //   }
  // }

  render() {
    const {
      classes,
      currentUser: { loading, notes },
      currentUser,
      // User: { notes },
      currentGroup,
    } = this.props;

    const isDesktop = this.state.isDesktop;
    console.log(this.state.note)
    let textWindow =
      currentGroup.id !== "notes" ? (
        <Conversation classes={classes} id={currentGroup.id} />
      ) : (
        <Notes classes={classes} />
      );

    return (
      <div>
        {loading ? (
          <LoadingPage />
        ) : (
          <div>
            <div style={{ position: "fixed", left: 10, top: 14 }}>
              <GroupTabs
                handleGroup={this.handleGroup}
                currentGroup={currentGroup.id}
                togglePopup={this.togglePopup}
              />
            </div>
            <div className={classes.root}>
              {isDesktop ? (
                <div style={{ display: "flex" }}>
                  <div className={classes.groupName}>
                    {currentGroup.id !== "notes" ? (
                      <h1>{currentGroup.name}</h1>
                    ) : (
                      <h1>{languageWords.Notes}</h1>
                    )}
                  </div>
                  <div>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className={classes.findNewGroup}
                      onClick={this.togglePopup.bind(this)}
                    >
                      {languageWords.FindNewGroup}
                    </Button> */}
                    {this.state.showPopup ? (
                      <AskQuestion closePopup={this.togglePopup.bind(this)} />
                    ) : null}
                  </div>
                  {currentGroup.id !== "notes" && (
                    <div className={classes.usersInChat}>
                      <UsersInChatTab />
                    </div>
                  )}
                </div>
              ) : (
                //IPHONE SIZE BELOW. MAKE SURE CHANGES MADE ON TOP ARE MADE FOR ON BOTTOM AS WELL
                <div style={{ display: "flex" }}>
                  <div className={classes.groupNameSmall}>
                    {currentGroup.id !== "notes" ? (
                      <h1>{currentGroup.name}</h1>
                    ) : (
                      <h1>{languageWords.Notes}</h1>
                    )}
                  </div>

                  {currentGroup.id !== "notes" && (
                    <div className={classes.usersInChat}>
                      <UsersInChatTab />
                    </div>
                  )}
                </div>
              )}
              <div
                style={{
                  border: "1px solid black",
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  overflowY: "scroll",
                  marginBottom: 10,
                  // backgroundColor: theme.palette.backgroundColor,
                }}
                class="scroll"
              >
                {textWindow}
              </div>

              <form onSubmit={this.handleSumbit}>
                <div style={{ display: "flex" }}>
                  <div className={classes.chatBar}>
                    <TextField
                      rows={1}
                      multiline
                      onKeyDown={(event)=> { 
                        if (event.key === "Enter" && !event.shiftKey) this.handleSumbit();
                        if( event.key=== "Enter" && event.shiftkey) console.log("this");
                      }}
                      id="outlined-textarea"
                      label={languageWords.textBoxPlaceholder}
                      placeholder="Thinking..."
                      name="note"
                      variant="outlined"
                      fullWidth
                      value={this.state.note}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div>
                    <IconButton
                      variant="contained"
                      onClick={this.handleSumbit}
                      type="submit"
                    >
                      <ArrowUpwardIcon className={classes.sendButton} />
                    </IconButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Home.propTypes = {
  UI: PropTypes.object.isRequired,
  removeGroupList: PropTypes.func.isRequired,
  getGroupList: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  chat: PropTypes.array.isRequired,
  createGroupChatbubble: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
  currentGroup: state.user.currentGroup,
  ref: state.data.ref,
  chat: state.user.chat,
  UI: state.UI,
});

const mapActionsToProps = {
  getQuestions,
  addNote,
  getGroupInfo,
  createGroupChatbubble,
  getNotesReset,
  getGroupList,
  removeGroupList,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(Home));
