import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField"
import { PropTypes } from 'prop-types';
import { connect } from "react-redux";

import {
    editNote,
    editChatbubble,
} from "../redux/actions/userAction";


 class EditText extends Component {
    constructor(props) {
        super(props);
        this.state = {
          text: "",
          notes: this.props.notes ? true: false,
        };
    }


    handleSumbit = (event) => {
        event.preventDefault();
        
        if( this.state.text.length > 0 ){
            if(this.state.notes){
                this.props.editNote(this.props.bubble.id, this.state.text);
                this.props.close();
            }
        if(!this.state.notes){
            console.log(this.props.currentGroup.id, this.props.bubble.id, this.state.text)
            this.props.editChatbubble(this.props.currentGroup.id, this.props.bubble.id, this.state.text);
            this.props.close();
            }
        }

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };


    render() {
        return (
       <form onSubmit={this.handleSumbit}>
                <TextField
                    autoFocus={true}
                    onKeyDown={(event)=>{ if(event.key === "Escape") this.props.close()} }
                    rowsMax={4}
                    id="outlined-textarea"
                    placeholder="Write new message"
                    name="text"
                    variant="outlined"
                    fullWidth
                    value={this.state.text}
                    onChange={this.handleChange}  
                />
            </form>
          
        )
    }
}

EditText.propTypes = {
    editChatbubble: PropTypes.func.isRequired,
    editNote: PropTypes.func.isRequired,
    currentGroup: PropTypes.object.isRequired
};
  
const mapStateToProps = (state) => ({
    currentGroup: state.user.currentGroup
});
  
const mapActionsToProps = {
editNote,
editChatbubble
};
export default connect(
mapStateToProps,
mapActionsToProps
)(EditText);

