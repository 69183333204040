import React, { Component } from "react";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";

// Components
import SettingsBar from "../Component/SettingsBar";
import MenuSettingsBar from "../Component/MenuSettingsBar";
import PropTypes from "prop-types";
import {connect } from "react-redux"
import { LoadingPage } from "./../util/LoadingPage";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    position: "fixed",
    textAlign: "center",
    top: 64,
  },
  headers: {
    textAlign: "left",
    marginLeft: "10px",
    marginTop: "50px",
  },
});

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 600 });
  }

  render() {
    //const { classes } = this.props;
    const isDesktop = this.state.isDesktop;
    const {loading} = this.props;

    return (
      <div style={{ height: "100%", width: "100%" }}>
      { loading ? <LoadingPage /> :
        
        isDesktop ? (
          <div style={{ height: "100%", width: "100%" }}>
            <SettingsBar />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              paddingRight: 20,
              paddingLeft: 20,
              position: "relative",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                textAlign: "left",
              }}
            >
              <MenuSettingsBar
                style={{ position: "absolute", left: -10, top: -30 }}
              />
            </div>
          </div>
        )
      }    
      </div>

    );
  }
}
Settings.propTypes = { 
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  loading: state.UI.loading,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(Settings));
