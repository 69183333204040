// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const DARK_THEME = "DARK_THEME";
export const LIGHT_THEME = "LIGHT_THEME";
export const ADD_FRIEND = "ADD_FRIEND";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUPCHAT = "SET_GROUPCHAT";
export const LANGUAGE_CHANGE = "LANGUAGE_CHANGE";
export const SET_NOTES = "SET_NOTES";

// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// DATA reducers types
export const SET_ANSWER = "SET_ANSWER";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_NEWQUESTIONS = "SET_NEWQUESTIONS";
export const LOADING_DATA = "LOADING_DATA";
export const LOADING_CLEAR = "LOADING_CLEAR";
export const ADD_GROUP = "ADD_GROUP";
export const LISTENER_ADD = "LISTENER_ADD";
export const REMOVE_LISTENER = "REMOVE_LISTENER";
export const REMOVE_GROUP = "REMOVE_GROUP"