import React from "react";
import "./App.css";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { PropTypes } from "prop-types";
import history from "./util/history";
// Redux
import { connect } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userAction";

// Components & Routes
import NavBar from "./Component/NavBar";
import AuthRoute from "./util/AuthRoute";
import VerifiedRoute from "./util/VerifiedRoute";
//import AdminRoute from "./util/AdminRoute";
import VerifyEmail from "./Component/VerifyEmail";

//Pages
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Privacy from "./Pages/Privacy";
import ForgotPassword from "./Pages/ForgotPass";
import ChangePassword from "./Pages/ChangePass";
import Profile from "./Pages/Profile";
import Home from "./Pages/Home";
import Settings from "./Pages/Settings";
import NotFound404 from "./Pages/NotFound404";
import { Router, Switch, Route } from "react-router-dom";

// MUI - Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { darkTheme, lightTheme } from "./util/theme";
import { LoadingPage } from "./util/LoadingPage";

// Import languages
import languageWords  from './Languages';


axios.defaults.baseURL =
  "https://us-central1-adaptmi-25b0b.cloudfunctions.net/api";

const token = localStorage.FBIdToken;

if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}

function App(props) {
  const { languageCode } = props;
  languageWords.setLanguage(languageCode);
  let themes = props.theme.theme ? darkTheme : lightTheme;
  let loading = props.loading;
  return (
    <MuiThemeProvider theme={themes}>
      <CssBaseline />
      {loading && <LoadingPage />}
      
      <Router history={history}>
      { props.authenticated && <NavBar />}
        <div className="container">
          <Switch>
            <AuthRoute exact path="/" component={Login} />
            <VerifiedRoute
              exact
              path="/changepassword"
              component={ChangePassword}
            />
            <AuthRoute
              exact
              path="/forgotpassword"
              component={ForgotPassword}
            />
          <VerifiedRoute exact path="/home" component={Home} />
          <VerifiedRoute exact path="/profile" component={Profile} />
          <VerifiedRoute exact path="/settings" component={Settings} />
          <VerifiedRoute exact path="/profile/privacy" component={Profile} />
          <VerifiedRoute exact path="/verifyemail" component={VerifyEmail} />
          <AuthRoute exact path="/login" component={Login} />
          <AuthRoute exact path="/register" component={Register} />
          <Route exact path="/privacy" component={Privacy} />
          <Route component={NotFound404} />
          </Switch>
        </div>
      </Router>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  loading: state.user.loading,
  authenticated: state.user.authenticated,
  languageCode: state.language.language
});

export default connect(mapStateToProps)(App);
