import LocalizedStrings from 'react-localization';

const languageWords = new LocalizedStrings({
    en:{
        // NavBar and  SettingBar
        login: 'Login',
        register: 'Register',
        privacy: 'Privacy',
        Home: 'Home',
        Profile: 'Profile',
        Settings: 'Settings',
        Logout: 'Logout',
        LookFeel: 'Look & Feel',
        ChangePassword: 'Change Password',
        // Login
        remember: 'Remember me',
        forgotPassword: 'Forgot password?',
        noAccount: "Don't have an account? Sign Up",
        password: 'Password',
        email: 'Email',
        Reset: 'Reset',

        // Register
        Username: "Username",
        PrivacyTermsAndConditions: 'Privacy Terms and Conditions',
        viaEmail: "I want to receive inspiration, marketing promotions and updates via email.",
        SignUp: 'Sign Up',
        haveAccount: 'Already have an account? Sign in',

        // Footer
        copyright: 'Copyright',
        
        // Notes
        welcome: 'Welcome',
        homePointOneBoldText: 'This page is all for you!',
        homePointOneText: 'Think of it as a personal notepad. Write down some thoughts, save some links, or anything else!',
        homePointTwoBoldText: 'Take a minute and look around.',
        homePointTwoText: 'There is lots to see so take your time.',
        homePointThreeBoldText: 'Make some new friends.',
        homePointThreeText: "When you're ready, find a new group to chat with.",
        Loading: 'Loading',

        // GroupTabs
        groupLabel: 'Groups',
        Notes: 'Notes',
        findNewGroup: 'Find new group',

        // FriendsDesktop and FriendsIpad
        textBoxPlaceholder: 'Time to say hi...',
        
        // Profile
        Groups: 'Groups', 
        Friends: 'Friends',

        // Conversation
        WELCOMEToYourNewGroup: 'WELCOME To Your New Group! ',
        newGroupOneText: 'This group is chosen by us for You. ',
        newGroupTwoText: 'Take some time and message other users in this group to talk about the things you love! ',
        makeNewFriends: 'Make some new friends. ',
        friendRequestText: 'When you are ready, send a friend request! ',

        // ChangePass
        NewPassword:'New Password',
        ConfirmPassword: 'Confirm Password',
        
        // PrivacySettings
        PrivacyPolicy: 'Privacy Policy ',
        LastUpdated: 'Last Updated ',
        PrivacyPolicyTextOne: 'Thank you for choosing to be part of our community at ',
        PrivacyPolicyTextTwo: ' We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our notice, or our practices with regards to your personal information, please contact us at ',
        PrivacyPolicyTextThree:'When you visit our website ',
        PrivacyPolicyTextFour: 'and use our services, you trust us with your personal information. We take your privacy very seriously. ',
        PrivacyPolicyTextFive: 'If you would like to download our privacy policy please click ',
        here: 'here',
        Share: 'Share This Link With Your Friends! ',
        CopyLink: 'Copy Link ',

        // LookFeel
        Theme: 'Theme',
        Language: 'Language',

        // EmaileList
        EmailList: 'Email List',
        Subscription: 'Subscription',


        signin: 'Sign in',
    },
    de:{
        // NavBar and  SettingBar
        login: 'Anmeldung',
        register: 'registrieren',
        privacy: 'Privatsphäre',
        Home: 'Zuhause',
        Profile: 'Profil',
        Settings: 'die Einstellungen',
        Logout: 'Ausloggen',
        LookFeel: 'Sehen und fühlen',
        ChangePassword: 'Passwort ändern?',
        // Login
        remember: 'Erinnere dich an mich',
        forgotPassword: 'Passwort vergessen',
        noAccount: "Sie haben noch keinen Account? Anmelden",
        password: 'Passwort',
        email: 'Email',
        Reset: 'Zurücksetzen',

        // Register
        Username: "Benutzername",
        PrivacyTermsAndConditions: 'Datenschutzbedingungen',
        viaEmail: "Ich möchte Inspiration, Marketingaktionen und Updates per E-Mail erhalten.",
        SignUp: 'Anmelden',
        haveAccount: 'Haben Sie bereits ein Konto? Anmelden',

        // Footer
        copyright: 'Urheberrechte',

        // Notes
        welcome: 'Herzlich willkommen',
        homePointOneBoldText: 'Diese Seite ist alles für Sie!',
        homePointOneText: 'Betrachten Sie es als persönlichen Notizblock. Schreiben Sie einige Gedanken auf, speichern Sie einige Links oder etwas anderes!',
        homePointTwoBoldText: 'Nehmen Sie sich eine Minute Zeit und schauen Sie sich um.',
        homePointTwoText: 'Es gibt viel zu sehen, nehmen Sie sich also Zeit.',
        homePointThreeBoldText: 'Finde neue Freunde. ',
        homePointThreeText: 'Wenn Sie bereit sind, suchen Sie eine neue Gruppe, mit der Sie chatten können.',
        Loading: 'Wird geladen',

        // GroupTabs
        groupLabel: 'Gruppen',
        Notes: 'Meine Notizen',
        findNewGroup: 'Neue Gruppe finden',

        // FriendsDesktop and FriendsIpad
        textBoxPlaceholder: 'Zeit, Hallo zu sagen ...',

        // Profile
        Groups: 'Gruppen',
        Friends: 'Freunde',

        // Conversation
        WELCOMEToYourNewGroup: 'WILLKOMMEN in Ihrer neuen Gruppe!',
        newGroupOneText: 'Diese Gruppe wird von uns für Sie ausgewählt.',
        newGroupTwoText: 'Nehmen Sie sich etwas Zeit und senden Sie anderen Benutzern in dieser Gruppe eine Nachricht, um über die Dinge zu sprechen, die Sie lieben!',
        makeNewFriends: 'Neue Freunde finden.',
        friendRequestText: 'Wenn Sie bereit sind, senden Sie eine Freundschaftsanfrage!',

        // ChangePass
        NewPassword:'Neues Passwort',
        ConfirmPassword: 'Passwort bestätigen',

        // PrivacySettings
        PrivacyPolicy: 'Datenschutzrichtlinie ',
        LastUpdated: 'Zuletzt aktualisier ',
        PrivacyPolicyTextOne: 'Vielen Dank, dass Sie sich entschieden haben, Teil unserer Community bei zu sein ',
        PrivacyPolicyTextTwo: ' Wir verpflichten uns, Ihre persönlichen Daten und Ihr Recht auf Privatsphäre zu schützen. Wenn Sie Fragen oder Bedenken bezüglich unserer Mitteilung oder unserer Praktiken in Bezug auf Ihre persönlichen Daten haben, wenden Sie sich bitte an ',
        PrivacyPolicyTextThree:'Wenn Sie unsere Website besuchen ',
        PrivacyPolicyTextFour: 'und nutzen Sie unsere Dienste, vertrauen Sie uns Ihre persönlichen Daten an. Wir nehmen Ihre Privatsphäre sehr ernst. ',
        PrivacyPolicyTextFive: 'Wenn Sie unsere Datenschutzrichtlinie herunterladen möchten, klicken Sie bitte auf ',
        here: 'hier',
        Share: 'Teile diesen Link mit deinen Freunden! ',
        CopyLink: 'Link kopieren ',

        // LookFeel
        Theme: 'Thema',
        Language: 'Sprache',

        // EmaileList
        EmailList: 'E-Mail-Liste',
        Subscription: 'Abonnement',

        signin: 'Anmelden',
    },
    it: {
        // NavBar and  SettingBar
        login:"Accesso",
        register:"Registrati",
        privacy:"vita privata",
        Home: 'Casa',
        Profile: 'Profilo',
        Settings: 'impostazioni',
        Logout: 'Disconnettersi',
        LookFeel: 'Guarda e senti',
        ChangePassword: 'Cambia la password',
        // Login
        remember: 'Ricordati di me',
        forgotPassword: 'Password dimenticata?',
        noAccount: "Non hai un account? Iscriviti",
        password: "parola d'ordine",
        email: 'E-mail',
        Reset: 'Ripristina',

        // Register
        Username: "Nome utente",
        PrivacyTermsAndConditions: 'Termini e condizioni sulla privacy',
        viaEmail: "Desidero ricevere ispirazione, promozioni di marketing e aggiornamenti via e-mail.",
        SignUp: 'Iscriviti',
        haveAccount: 'Hai già un account? Registrati',

        // Footer
        copyright: "Diritto d'autore",

        // Notes
        welcome: 'Benvenuta',
        homePointOneBoldText: 'Questa pagina è tutta per te!',
        homePointOneText: 'Pensalo come un blocco note personale. Scrivi alcuni pensieri, salva alcuni link o qualsiasi altra cosa!',
        homePointTwoBoldText: 'Prenditi un minuto e guardati intorno.',
        homePointTwoText: "C'è molto da vedere, quindi prenditi il ​​tuo tempo.",
        homePointThreeBoldText: 'Fai nuove amicizie.',
        homePointThreeText: 'Quando sei pronto, trova un nuovo gruppo con cui chattare.',
        Loading: 'Caricamento',

        // GroupTabs
        groupLabel: 'gruppi',
        Notes: 'I miei appunti',
        findNewGroup: 'Trova un nuovo gruppo',

        // FriendsDesktop and FriendsIpad
        textBoxPlaceholder: 'È ora di dire ciao ...',

        // Profile
        Groups: 'gruppi',
        Friends: 'Amici',

        // Conversation
        WELCOMEToYourNewGroup: 'BENVENUTO nel tuo nuovo gruppo!',
        newGroupOneText: 'Questo gruppo è stato scelto da noi per te.',
        newGroupTwoText: 'Prenditi del tempo e invia messaggi agli altri utenti di questo gruppo per parlare delle cose che ami!',
        makeNewFriends: 'Fai nuove amicizie.',
        friendRequestText: 'Quando sei pronto, invia una richiesta di amicizia!',

        // ChangePass
        NewPassword:'Nuova password',
        ConfirmPassword: 'Conferma password',

        // PrivacySettings
        PrivacyPolicy: 'Informativa sulla privacy ',
        LastUpdated: 'Ultimo aggiornamento ',
        PrivacyPolicyTextOne: 'Grazie per aver scelto di far parte della nostra community di ',
        PrivacyPolicyTextTwo: ' Ci impegniamo a proteggere le tue informazioni personali e il tuo diritto alla privacy. In caso di domande o dubbi sulla nostra comunicazione o sulle nostre pratiche in merito alle informazioni personali, vi preghiamo di contattarci all indirizzo ',
        PrivacyPolicyTextThree:'Quando visiti il nostro sito Web ',
        PrivacyPolicyTextFour: 'e usa i nostri servizi, ti fidi di noi con le tue informazioni personali. Prendiamo molto sul serio la vostra privacy. ',
        PrivacyPolicyTextFive: 'Se desideri scaricare la nostra politica sulla privacy, fai clic su ',
        here: 'qui',
        Share: 'Condividi questo link con i tuoi amici! ',
        CopyLink: 'Copia collegamento ',

        // LookFeel
        Theme: 'Tema',
        Language: 'Lingua',

        // EmaileList
        EmailList: 'Elenco e-mail"',
        Subscription: 'Abbonamento',

        signin: 'Registrati',
       
    },
    sp:{
        // NavBar and  SettingBar
        login: 'Login',
        register: 'Registrarse',
        privacy: 'Privacidad',
        Home: 'Hogar',
        Profile: 'Perfil',
        Settings: 'Configuración',
        Logout: 'Cerrar sesión',
        LookFeel: 'Look & Feel',
        ChangePassword: 'Cambiar contraseña',
        // Login
        remember: 'Recuérdame',
        forgotPassword: 'Olvidé mi contraseña?',
        noAccount: "¿No tienes una cuenta? Regístrate",
        password: 'Contraseña',
        email: 'Correo electrónico',
        Reset: 'Restablecer',
        
        // Register
        Username: "Nombre de usuario",
        PrivacyTermsAndConditions: 'Términos y condiciones de privacidad',
        viaEmail: "Quiero recibir inspiración, promociones de marketing y actualizaciones por correo electrónico.",
        SignUp: 'Registrarse',
        haveAccount: '¿Ya tienes una cuenta? Registrarse',

        // Footer
        copyright: 'Copyright',
        
        // Notes
        welcome: 'Bienvenido',
        homePointOneBoldText: '¡Esta página es todo para ti!',
        homePointOneText: 'Piense en ello como un bloc de notas personal. ¡Escribe algunos pensamientos, guarda algunos enlaces o cualquier otra cosa!',
        homePointTwoBoldText: 'Tómese un minuto y mire a su alrededor.',
        homePointTwoText: 'Hay mucho que ver, así que tómate tu tiempo.',
        homePointThreeBoldText: 'Haz nuevos amigos',
        homePointThreeText: "Cuando estés listo, busca un nuevo grupo para chatear",
        Loading: 'Cargando',

        // GroupTabs
        groupLabel: 'Grupos',
        Notes: 'Notas',
        findNewGroup: 'Buscar nuevo grupo',

        // FriendsDesktop and FriendsIpad
        textBoxPlaceholder: 'Hora de decir hola ...',
        
        // Profile
        Groups: 'Grupos', 
        Friends: 'Amigos',

        // Conversation
        WELCOMEToYourNewGroup: 'BIENVENIDO A SU NUEVO GRUPO',
        newGroupOneText: 'Este grupo es elegido por nosotros para usted',
        newGroupTwoText: 'Tómese un tiempo y envíe mensajes a otros usuarios de este grupo para hablar sobre las cosas que ama!',
        makeNewFriends: 'Haz nuevos amigos',
        friendRequestText: 'Cuando esté listo, envíe una solicitud de amistad',

        // ChangePass
        NewPassword:'Nueva contraseña',
        ConfirmPassword: 'Confirmar contraseña',

        // PrivacySettings
        PrivacyPolicy: 'Política de privacidad ',
        LastUpdated: 'Última actualización ',
        PrivacyPolicyTextOne: 'Gracias por elegir ser parte de nuestra comunidad en ',
        PrivacyPolicyTextTwo: ' Estamos comprometidos a proteger su información personal y su derecho a la privacidad. Si tiene alguna pregunta o inquietud sobre nuestro aviso o nuestras prácticas con respecto a su información personal, contáctenos en ',
        PrivacyPolicyTextThree: 'Cuando visita nuestro sitio web ',
        PrivacyPolicyTextFour: 'y utiliza nuestros servicios, confía en nosotros con su información personal. Nos tomamos muy en serio su privacidad. ',
        PrivacyPolicyTextFive: 'Si desea descargar nuestra política de privacidad, haga clic en ',
        here: 'aquí ',
        Share: '¡Comparte este enlace con tus amigos! ',
        CopyLink: 'Copiar enlace ',

        // LookFeel
        Theme: 'Tema',
        Language: 'Idioma',

        // EmaileList
        EmailList: 'Lista de correo electrónico',
        Subscription: 'Suscripción',

        signin: 'Iniciar sesión',
        
    },
    ar: {
        // NavBar and  SettingBar
        login:"دخول",
        register:"تسجيل",
        privacy:"الخصوصية",
        Home: 'الصفحة الرئيسية',
        Profile: 'الملف الشخصي',
        Settings: 'الإعدادات',
        Logout: 'تسجيل خروج',
        LookFeel: 'انظر و اشعر',
        ChangePassword: 'تغيير كلمة المرور',
        // Login
        remember: "تذكرني ",
        forgotPassword: 'نسيت كلمة المرور؟',
        noAccount: " أليس لديك حساب؟ اشترك ",
        password: " كلمة المرور ",
        email: ' البريد الإلكتروني ',
        Reset: ' إعادة ',
        
        // Register
        Username: " اسم المستخدم ",
        PrivacyTermsAndConditions: ' شروط وأحكام الخصوصية ',
        viaEmail: " أريد تلقي الإلهام والترويج والتحديثات التسويقية عبر البريد الإلكتروني. ",
        SignUp: ' تسجيل ',
        haveAccount: ' هل لديك حساب بالفعل؟ تسجيل الدخول ',

        // Footer
        copyright: "حقوق النشر",

        // Notes
        welcome: ' اهلا وسهلا ',
        homePointOneBoldText: ' ..هذه الصفحة كلها لك ',
        homePointOneText: ' فكر في الأمر كمفكرة شخصية. اكتب بعض الأفكار ، أو احفظ بعض الروابط ، أو أي شيء آخر !',
        homePointTwoBoldText: ' خذ دقيقة وانظر حولك ',
        homePointTwoText: " هناك الكثير لتراه لذا خذ وقتك ",
        homePointThreeBoldText: ' تكوين صداقات جديدة ',
        homePointThreeText: ' عندما تكون جاهزًا ، ابحث عن مجموعة جديدة للدردشة معها. ',
        Loading: ' جارٍ التحميل ',

        // GroupTabs
        groupLabel: 'مجموعات',
        Notes: 'ملاحظاتي',
        findNewGroup: 'العثور على مجموعة جديدة',

        // FriendsDesktop and FriendsIpad
        textBoxPlaceholder: 'حان الوقت لقول مرحبًا ...',

        // Profile
        Groups: 'مجموعات',
        Friends: 'الأصدقاء',

        // Conversation
        WELCOMEToYourNewGroup: 'مرحبًا بمجموعتك الجديدة!',
        newGroupOneText: 'نختار هذه المجموعة من أجلك.',
        newGroupTwoText: 'خذ بعض الوقت وأرسل رسائل إلى مستخدمين آخرين في هذه المجموعة للتحدث عن الأشياء التي تحبها!',
        makeNewFriends: 'تكوين صداقات جديدة.',
        friendRequestText: 'عندما تكون جاهزًا ، أرسل طلب صداقة!',
        
        // ChangePass
        NewPassword:'كلمة مرور جديدة',
        ConfirmPassword: 'تأكيد كلمة المرور',

        // PrivacySettings
        PrivacyPolicy: ' سياسة الخصوصية ',
        LastUpdated: ' آخر تحديث ',
        PrivacyPolicyTextOne: ' شكرًا لاختيارك أن تكون جزءًا من مجتمعنا في ',
        PrivacyPolicyTextTwo: ' نحن ملتزمون بحماية معلوماتك الشخصية وحقك في الخصوصية. إذا كان لديك أي أسئلة أو مخاوف بشأن إشعارنا ، أو ممارساتنا فيما يتعلق بمعلوماتك الشخصية ، يرجى الاتصال بنا على ',
        PrivacyPolicyTextThree: ' عندما تزور موقعنا على الويب ',
        PrivacyPolicyTextFour: ' واستخدم خدماتنا ، فأنت تثق بنا بمعلوماتك الشخصية. نحن نأخذ خصوصيتك على محمل الجد. ',
        PrivacyPolicyTextFive: ' إذا كنت ترغب في تنزيل سياسة الخصوصية الخاصة بنا ، فيرجى النقر ',
        here: ' هنا ',
        Share: ' شارك هذا الرابط مع أصدقائك! ',
        CopyLink: ' نسخ الرابط ',

        // LookFeel
        Theme: 'الخلفية',
        Language: 'اللغة',

        // EmaileList
        EmailList: ' قائمة البريد الإلكتروني ',
        Subscription: ' الاشتراك ',

        signin: " تسجيل الدخول ",
    }
});

export default languageWords;