import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  SET_GROUP,
  SET_GROUPCHAT,
  SET_NOTES,
  
} from "./../types";

const initialState = {
  authenticated: false,
  loading: false,
  verified: false,
  credentials: {
    handle: null
  },
  groups: [],
  currentGroup: {id:"notes"},
  chat: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        ...action.payload,
      };
    case SET_GROUP:
      return {
        ...state,
        currentGroup: { ...action.payload },
      };
    case SET_NOTES:
      return {
        loading: true,
        ...state,
        currentGroup: {id: "notes"},
      };
    case SET_GROUPCHAT:
      return {
        ...state,
        ...action.payload,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
