import React, { Component } from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

// MUI - Material UI
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

//Components

//Images
// import amofficial from "../Images/amofficial.png";
// import amcircle from "../Images/amcircle.png";
import pink from "../Images/pink.png";

// Import languages
import languageWords  from '../Languages';

// Redux elements
import { connect } from "react-redux";
import { logoutUser} from "../redux/actions/userAction";
import { Avatar } from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    color: "white",
    marginRight: "auto",
  },
  titleIn: {
    flexGrow: 1,
    textAlign: "center",
    color: "white",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleIphone: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    margin: "auto",
    textDecoration: "none",
  },
  find: {
    display: "flex",
  },
  wrapper: {
    margin: "15px",
  },
});

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 466 });
  }

  handleMenu = (event) => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleLogout = (event) => {
    this.props.logoutUser();
    this.setState({
      open: false,
    });
  };

  // handleTheme = (event) => {
  //   this.props.setTheme(this.props.theme);
  // };

  render() {
    const { classes, authenticated, imageUrl } = this.props;
    const isDesktop = this.state.isDesktop;

    return (
      <div>
        {!authenticated ? (
          <AppBar position="static">
            {isDesktop ? (
              <Toolbar>
                <Typography variant="h4" className={classes.title}>
                  adaptMi
                </Typography>
                <img
                  src={pink}
                  alt="logo"
                  style={{
                    width: 40,
                    height: 40,
                    position: "absolute",
                    left: 160,
                  }}
                ></img>
                <Button color="inherit" component={Link} to="/login">
                  {languageWords.Login}
                </Button>
                <Button color="inherit" component={Link} to="/register">
                  {languageWords.Register}
                </Button>
                <Button color="inherit" component={Link} to="/privacy">
                  {languageWords.Privacy}
                </Button>
              </Toolbar>
            ) : (
              <Toolbar>
                <img
                  src={pink}
                  alt="logo"
                  style={{
                    width: 40,
                    height: 40,
                    position: "absolute",
                    left: 10,
                  }}
                ></img>
                <div style={{ position: "absolute", right: 0 }}>
                  <Button color="inherit" component={Link} to="/login">
                    Login
                  </Button>
                  <Button color="inherit" component={Link} to="/register">
                    Register
                  </Button>
                  <Button color="inherit" component={Link} to="/privacy">
                    Privacy
                  </Button>
                </div>
              </Toolbar>
            )}
          </AppBar>
        ) : (
          <AppBar position="static">
            <Toolbar>
              {isDesktop ? (
                <div className={classes.titleIn}>
                  <Typography variant="h4" style={{ marginRight: 15 }}>
                    adaptMi
                  </Typography>
                  <img
                    src={pink}
                    alt="logo"
                    style={{ width: 40, height: 40 }}
                  ></img>
                </div>
              ) : (
                <div className={classes.titleIphone}></div>
              )}
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={this.handleMenu}
              >
                <Avatar src={imageUrl} />
              </IconButton>

              <Menu
                id="menu-appbar"
                //anchorEl={this.state.anchorEl}

                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleClose}>
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to="/home"
                  >
                    {languageWords.Home}
                  </Link>
                </MenuItem>
                <MenuItem onClick={this.handleClose}>
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to="/Profile"
                  >
                    {languageWords.Profile}
                  </Link>
                </MenuItem>
                <MenuItem onClick={this.handleClose}>
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to="/Settings"
                  >
                    {languageWords.Settings}
                  </Link>
                </MenuItem>
                <MenuItem onClick={this.handleLogout}>{languageWords.Logout}</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        )}
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,

};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  imageUrl: state.user.credentials.imageUrl,

});

const mapActionsToProps = {
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(NavBar));
