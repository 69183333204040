import React, { Component } from "react";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";

//Images
import Doge from "../Images/Doge.png";

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    top: 65,
    left: 0,
  },
});

export class NotFound404 extends Component {
  render() {
    //const { classes } = this.props;

    return (
      <div>
        <h1>404 Page Not Found</h1>
        <img src={Doge} alt="dog" />
        <br></br>
        <br></br>
        <h1>
          Dont worry, back to{" "}
          <a href={"/"} style={{ color: "red" }}>
            adaptMi
          </a>
        </h1>
      </div>
    );
  }
}

export default withStyles(useStyles)(NotFound404);
