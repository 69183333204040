import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";

//Material - UI
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

//component
import QuestionCard from "./QuestionCard";
import { getQuestions, clearQuestions } from "./../redux/actions/dataAction";
import { newGroup } from "./../redux/actions/userAction";
import { IconButton } from "@material-ui/core";
import { LoadingPage } from './../util/LoadingPage';
const questonsCat = [
  "academia",
  "art",
  "books",
  "food",
  "movies",
  "music",
  "podcasts",
  "sportTeams",
  "sports",
  "tvShows",
  "videoGames",
];

// const useStyles = (theme) => ({
//   root: {},
// });

class AskQuestion extends Component {
  constructor() {
    super();
    this.state = {
      firstQuestion: "",
      secondQuestion: "",
      finalQuestion: 0,
      answerQuestions: [],
      favAnswer: null,
      value: "",
      academia: [],
      art: [],
      books: [],
      food: [],
      movies: [],
      music: [],
      podcasts: [],
      sportTeams: [],
      sports: [],
      tvShows: [],
      videoGames: [],
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentWillMount() {
    this.setState({
      academia: this.unqiueNumbers(),
      art: this.unqiueNumbers(),
      books: this.unqiueNumbers(),
      food: this.unqiueNumbers(),
      movies: this.unqiueNumbers(),
      music: this.unqiueNumbers(),
      podcasts: this.unqiueNumbers(),
      sportTeams: this.unqiueNumbers(),
      sports: this.unqiueNumbers(),
      tvShows: this.unqiueNumbers(),
      videoGames: this.unqiueNumbers(),
    });
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    this.setState({
      academia: this.unqiueNumbers(),
      art: this.unqiueNumbers(),
      books: this.unqiueNumbers(),
      food: this.unqiueNumbers(),
      movies: this.unqiueNumbers(),
      music: this.unqiueNumbers(),
      podcasts: this.unqiueNumbers(),
      sportTeams: this.unqiueNumbers(),
      sports: this.unqiueNumbers(),
      tvShows: this.unqiueNumbers(),
      videoGames: this.unqiueNumbers(),
    });
    this.twoQuestionLoad();
  }
  handleChangeValue1 = (e) => {

    this.setState({ value: "q1" });
  };
  handleChangeValue2 = (e) => {

    this.setState({ value: "q2" });
  };

  singleQuestionLoad() {
    //Array of random questions array of 14, maps (qCat, Index)
    const qCat = this.getQuestionCat();
    let q = {
      qcat: qCat,
      index: this.popQuestionIndex(qCat),
    };

    if (this.state.value === "q1") {
      this.setState({ secondQuestion: qCat });
      this.props.getQuestions(q, "Second");
    } else if (this.state.value === "q2") {
      this.setState({ firstQuestion: qCat });
      this.props.getQuestions(q, "First");
    } else console.log("error with the q selection");
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    if (this.state.finalQuestion > 6) {
      this.findMostFrequent();
      alert(
        "Check your groups list to see if you have been placed into a new group!"
      );
    }
    this.props.clearQuestions()
  }

  findMostFrequent() {
    var arr = this.state.answerQuestions;
    var newArr = arr.slice().sort(),
      most = [undefined, 0],
      counter = 0;
    newArr.reduce(function (old, chr) {
      old === chr
        ? ++counter > most[1] && (most = [chr, counter])
        : (counter = 1);
      return chr;
    });

    // alert(most[0] + " ( "+most[1]+" times )");
    this.props.newGroup({ qCat: most[0] }, this.props.history);
  }
  twoQuestionLoad() {
    //Array of random questions array of 14, maps (qCat, Index)

    const qCat1 = this.getQuestionCat();
    const qCat2 = this.getQuestionCat();
    let q1 = {
      qcat: qCat1,
      index: this.popQuestionIndex(qCat1),
    };

    let q2 = {
      qcat: qCat2,
      index: this.popQuestionIndex(qCat2),
    };

    this.setState({ firstQuestion: qCat1 });
    this.setState({ secondQuestion: qCat2 });
    this.props.getQuestions(q1, "First", this.props.history);
    this.props.getQuestions(q2, "Second", this.props.history);
  }
  popQuestionIndex(qCat) {
    var questionIndex = this.state[qCat][0];

    this.state[qCat].shift(); //remove first element

    return questionIndex;
  }
  getQuestionCat() {
    return questonsCat[this.getRndInteger(0, 11)];
  }

  unqiueNumbers() {
    for (var a = [], i = 0; i < 10; ++i) a[i] = i;

    return this.shuffle(a);
  }

  shuffle(array) {
    var tmp,
      current,
      top = array.length;
    if (top)
      while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
      }
    return array;
  }

  handleNeither = (e) => {
    this.twoQuestionLoad();
    var a = this.state.answerQuestions;
    a.push("dc");
    this.setState({ answerQuestions: a });
    this.setState({
      finalQuestion: this.state.finalQuestion + 1,
    });
  };

  handleNextQuestions = (event) => {
    var a = this.state.answerQuestions;
    if (this.state.value === "q1") a.push(this.state.firstQuestion);
    else if (this.state.value === "q2") a.push(this.state.secondQuestion);
    else {
      alert("Please choose an image or hit 'skip'");
      return;
    }
    this.setState({ answerQuestions: a });
    this.setState({
      finalQuestion: this.state.finalQuestion + 1,
    });
    this.singleQuestionLoad();
    this.setState({ value: "" });
  };
  // min = 0 max = 10, numbers from 0-9
  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 466 });
  }

  render() {
    //const classes = this.props;
    const qCat = this.state.firstQuestion;
    const qCat2 = this.state.secondQuestion;
    const q1 = this.props.questionFirst;
    const q2 = this.props.questionSecond;
    const isDesktop = this.state.isDesktop;
    const { data:{loading}} = this.props;

    return (
      <div>
        {loading &&  <LoadingPage chat={false}/> }
        {isDesktop ? (
          <div className="popup">
            <div className="popup_inner">
              <div className="button_exit">
                <IconButton>
                  <CloseIcon
                    variant="contained"
                    color="primary"
                    onClick={this.props.closePopup}
                    style={{ width: 30, height: 30 }}
                  />
                </IconButton>
              </div>
              <h1 style={{ fontSize: 25, marginBottom: 10 }}>
                Which do you prefer?
              </h1>

              {q1[qCat] !== undefined && q2[qCat2] !== undefined && (
                <QuestionCard
                  firstQuestion={q1[qCat]}
                  secondQuestion={q2[qCat2]}
                  onChangeValue1={this.handleChangeValue1}
                  onChangeValue2={this.handleChangeValue2}
                />
              )}
              <div>
                <div className="button_skip">
                  {this.state.finalQuestion > 6 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.props.closePopup}
                      disabled={loading}
                    >
                      Skip
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNeither}
                      disabled={loading}
                      disable={this.state.finalQuestion === 6 ? true : false}
                    >
                      Skip
                    </Button>
                  )}
                </div>

                <div className="button_next">
                  {this.state.finalQuestion > 6 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.props.closePopup}
                      disabled={loading}
                    >
                      Done
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNextQuestions}
                      disabled={loading}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="popup">
              <div className="popup_inner">
                <div style={{ position: "absolute", right: 0, top: 0 }}>
                  <IconButton>
                    <CloseIcon
                      variant="contained"
                      color="primary"
                      onClick={this.props.closePopup}
                      style={{ width: 20, height: 20 }}
                    />
                  </IconButton>
                </div>
                <h1 style={{ fontSize: 20, marginBottom: 10 }}>
                  Which do you prefer?
                </h1>

                {q1[qCat] !== undefined && q2[qCat2] !== undefined && (
                  <QuestionCard
                    firstQuestion={q1[qCat]}
                    secondQuestion={q2[qCat2]}
                    onChangeValue1={this.handleChangeValue1}
                    onChangeValue2={this.handleChangeValue2}
                  />
                )}
                <div>
                  <div className="button_skip">
                    {this.state.finalQuestion > 6 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.props.closePopup}
                        disabled={loading}
                      >
                        Skip
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNeither}
                        disable={this.state.finalQuestion === 6 ? true : false}
                        disabled={loading}
                      >
                        Skip
                      </Button>
                    )}
                  </div>

                  <div className="button_next">
                    {this.state.finalQuestion > 6 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.props.closePopup}
                        disabled={loading}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNextQuestions}
                        disabled={loading}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

AskQuestion.propTypes = {
  questionFirst: PropTypes.object.isRequired,
  questionSecond: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
  getQuestions: PropTypes.func.isRequired,
  newGroup: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  questionFirst: state.data.firstQuestion,
  questionSecond: state.data.secondQuestion,
  questions: state.data,
});

const mapActionsToProps = {
  getQuestions,
  newGroup,
  clearQuestions
};

export default connect(mapStateToProps, mapActionsToProps)(AskQuestion);
