import React, { Component } from "react";
import PropTypes from "prop-types";
import pink from "../Images/pink.png";

// Material UI - Mui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "react-router-dom/Link";
import { CircularProgress } from "@material-ui/core";

// Redux
import { connect } from "react-redux";

// Import languages
import languageWords  from '../Languages';

// components
import { forgotPassword } from "../redux/actions/userAction";

const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}))(TextField);

const useStyles = (theme) => ({
  paper: {
    marginTop: "20",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    backgroundColor: "#00A8E8",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  submit: {
    margin: "20px auto auto auto",
    position: "relative",
    marginBottom: 10,
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  customError: {
    color: theme.palette.error,
    fontSize: "0.8rem",
    textAlign: "left",
    margin: "20px auto 20px auto",
  },
  progress: {
    position: "absolute",
  },
  checkBox: {
    textAlign: "left",
    marginTop: "10px",
  },
  links: {
    margin: "10px auto 20px auto",
  },
  loginLink: {
    color: theme.palette.secondary.light,
    textDecoration: "none",
  },
});

export class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
    };
  }

  handleSumbit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
    };

    if (this.state.email !== "") {
      this.props.forgotPassword(userData, this.props.history);

      alert(
        "Thank you. If you are a user of adaptMi you should recieve a password reset email shortly."
      );
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    // const { errors } = this.state; commented this out, value is assigned but never used
    return (
      <Container component="main" maxWidth="xs">
        <div container className={classes.paper}>
          <Typography variant="h4" className={classes.pageTitle}>
            {languageWords.forgotPassword}
          </Typography>
          <Avatar className={classes.avatar} src={pink} alt="logo" />
          <form
            className={classes.form}
            noValidate
            onSubmit={this.handleSumbit}
          >
            <CssTextField
              id="email"
              label={languageWords.email}
              type="email"
              name="email"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus
              onChange={this.handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {languageWords.Reset}
              {loading && (
                <CircularProgress size={30} className={classes.progress} />
              )}
            </Button>
          </form>

          <Link to="/" className={classes.loginLink} variant="body2">
            {languageWords.haveAccount}
          </Link>
        </div>
      </Container>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
});

const mapActionsToProps = {
  forgotPassword,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(ForgotPassword));
