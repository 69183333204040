import React, { Component } from "react";
import { PropTypes } from "prop-types";

import firebase from "../util/config";
import { removeElem, editElem } from "../util/chatHelper";

// Redux
import { connect } from "react-redux";
// import { getQuestions } from "../redux/actions/dataAction";
import { editChatbubble, deleteChatbubble } from "../redux/actions/userAction";

// Material UI
import CreateIcon from "@material-ui/icons/Create";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";

// Import languages
import languageWords from "../Languages";

// import ExploreIcon from "@material-ui/icons/Explore";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Divider } from "@material-ui/core";
import LoadingPage from "../util/LoadingPage";
import EditText from "./EditText";

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [],
      readError: null,
      groupId: null,
      unsubscribe: null,
      id: this.props.id,
      editBox: null,
    };
    this.state.messagesEndRef = React.createRef();
    this.getListner = this.getListner.bind(this);
  }

  componentDidMount() {
    //this.setState({ groupId: this.props.currentGroup.id });
    this.scrollToBottom();
    if (this.state.id) this.getListner(this.state.id);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.currentGroup) {
      this.setState({ groupId: nextProps.currentGroup.id });
    }
  }
  componentWillUnmount() {
    if (this.state.unsubscribe != null) this.state.unsubscribe(); //remove listener
  }
  scrollToBottom = () => {
    this.state.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  getListner(id) {
    if (id !== null && id !== "notes") {
      let ref = firebase
        .firestore()
        .collection("groups")
        .doc(id)
        .collection("Chat")
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
          let chat = this.state.chats;
          let changes = snapshot.docChanges();
          changes.forEach((change) => {
            if (change.type === "added") {
              chat.push({ ...change.doc.data(), id: change.doc.id });
            }
            if (change.type === "removed") {
              chat = removeElem(chat, change.doc);
            }
            if (change.type === "modified") {
              chat = editElem(chat, change.doc);
            }
          });
          this.setState({ chats: chat });
          this.setState({ unsubscribe: ref });
        });
    }
  }
  handleDeleteMessage = (event) => {
    this.props.deleteChatbubble(this.props.currentGroup.id, event);
  };
  handleEditMessage = (event) => {
    if (this.state.editBox !== event) this.setState({ editBox: event });
    else this.setState({ editBox: null });
  };

  handleEditBox = (event) => {
    this.setState({ editBox: null });
  };

  render() {
    const {
      classes,
      // UI: { loading },
      //User: { notes },
      // currentUser: { chat },
      currentUser,
    } = this.props;

    return (
      <div>
        <div className={classes.welcome} style={{ minHeight: 550 }}>
          <h1>{languageWords.WELCOMEToYourNewGroup}!</h1>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CreateIcon style={{ marginRight: 20 }} />
            <p style={{ textAlign: "left", fontSize: 18, maxWidth: 500 }}>
              <b>{languageWords.newGroupOneText}</b>
              {languageWords.newGroupTwoText}
            </p>
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ExploreIcon style={{ marginRight: 20 }} />
            <p style={{ textAlign: "left", fontSize: 18, maxWidth: 500 }}>
              <b>Take a minute and look around.</b> There is lots to see so take
              your time.{" "}
            </p>
          </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PersonAddIcon style={{ marginRight: 20 }} />
            <p style={{ textAlign: "left", fontSize: 18, maxWidth: 500 }}>
              <b>{languageWords.makeNewFriends}</b>
              {languageWords.friendRequestText}
            </p>
          </div>

          {/* <Divider style={{ marginBottom: 10 }} /> */}

          <div>
            <ul className={classes.chatList}>
              {this.state.chats.length >= 0 ? (
                this.state.chats.map((item, index) => (
                  <li key={index} className="convoChat">
                    {currentUser.credentials.userId !== item.user ? (
                      <p>{item.who}  <span style={{fontSize: 12}}> 
                        {new Date(firebase.firestore.Timestamp.now().seconds*1000).toLocaleDateString()}
                        </span>
                      </p>
                    ) : (
                      <div>
                        <div className="convoChat__hover">
                          <Tooltip title="Edit Message" placement="top">
                            <IconButton
                              onClick={() => this.handleEditMessage(index)}
                              className="button"
                            >
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Message" placement="top">
                            <IconButton
                              onClick={() => this.handleDeleteMessage(item.id)}
                              className="button"
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <p style={{ color: "#7688d4" }}>{item.who} 
                          <span style={{fontSize: 12}}> {new Date(firebase.firestore.Timestamp.now().seconds*1000).toLocaleDateString()}</span>
                        </p>
                      </div>
                    )}
                    <div style={{ width: "93%" }}>
                      {this.state.editBox === index ? (
                        <EditText
                          bubble={item}
                          notes={false}
                          close={this.handleEditBox}
                        />
                      ) : (
                        <p style={{ marginTop: -15 }}>{item.message}</p>
                      )}
                    </div>
                    <Divider style={{ marginTop: 25 }} />
                  </li>
                ))
              ) : (
                // <div>Loading...</div>
                // <img src={animation} alt="loading..." />
                // <div style={{marginBottom: 100, bottom: 50}}><LoadingPage chat={true}/></div>
                <div></div>
              )}
            </ul>
            <div ref={this.state.messagesEndRef} />
          </div>
        </div>
        {this.state.chats.length <= 0 && <LoadingPage chat={true} />}
      </div>
    );
  }
}

Conversation.propTypes = {
  UI: PropTypes.object.isRequired,
  addFriendF: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  User: PropTypes.object.isRequired,
  addNote: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  chat: PropTypes.array.isRequired,
  createGroupChatbubble: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
  currentGroup: state.user.currentGroup,
  chat: state.user.chat,
  UI: state.UI,
  User: state.user.credentials,
});

export default connect(mapStateToProps, { editChatbubble, deleteChatbubble })(
  Conversation
);