export const removeElem = (array, elem) =>  {
    let index = -1;
    for(var i = 0;  i < array.length; i++){
      if(array[i].id === elem.id){
        index = i;
        break;
      }
    }
    if (index > -1) {
      array.splice(index, 1);
    }
    return array
}

export const editElem = (array, elem) =>  {
    for(var i = 0;  i < array.length; i++){
      if(array[i].id === elem.id){
        array[i] = {...elem.data(), id: elem.id};
        break;
      }
    }
    return array;
}


