import React, { Component } from "react";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";
import ThemeSwitch from "../../Component/ThemeSwitch";
import LanguageSwitch from '../../Component/LanguageSwitch'

// Import languages
import languageWords  from '../../Languages'

import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness5Icon from "@material-ui/icons/Brightness5";

const useStyles = (theme) => ({
  center: {
    position: "fixed",
    right: "600px",
  },
  root: {
    position: "fixed",
    left: "280px",
    textAlign: "left",
    paddingRight: "280px",
    paddingBottom: "66px",
    height: "100%",
    width: "100%",
  },
  language: {
    position: "static",
    left: "280px",
    textAlign: "left",
  },
  iphone: {
    margin: "auto",
    textAlign: "center",
  },
});

class LookFeel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 600 });
  }

  render() {
    const isDesktop = this.state.isDesktop;
    const { classes } = this.props;
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {isDesktop ? (
          <div className={classes.root}>
            <div
              style={{
                position: "relative",
                overflowY: "auto",
                height: "100%",
                width: "100%",
              }}
            >
              <h1>{languageWords.LookFeel}</h1>
              <div style={{ marginBottom: 30 }}>
                <h3>{languageWords.Theme}</h3>
                <div style={{ display: "flex" }}>
                  <Brightness4Icon
                    style={{ marginTop: "10px" }}
                    onClick={this.handleTheme}
                  />
                  <div style={{ marginTop: "5px" }}>
                    <ThemeSwitch />
                  </div>
                  <Brightness5Icon style={{ marginTop: "10px" }} />
                </div>
              </div>
              <div className={classes.language}>
                <h3>{languageWords.Language}</h3>
                <LanguageSwitch />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.iphone}>
            <h1>{languageWords.LookFeel}</h1>
            <div>
              <h3>{languageWords.Theme}</h3>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <Brightness4Icon
                  style={{ marginTop: "10px" }}
                  onClick={this.handleTheme}
                />
                <div style={{ marginTop: "5px" }}>
                  <ThemeSwitch />
                </div>
                <Brightness5Icon style={{ marginTop: "10px" }} />
              </div>
              <h3>{languageWords.Language}</h3>
              <LanguageSwitch />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(useStyles)(LookFeel);
