import React, { Component } from "react";

import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/userAction";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
//import MenuList from "@material-ui/core/MenuList";
//import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

// Pages
import LookFeel from "../Pages/UserPages/LookFeel";
//import Notifications from "../Pages/UserPages/Notifications";
import ChangePass from "../Pages/ChangePass";
import PrivacySettings from "../Pages/UserPages/PrivacySettings";
import EmailList from "../Pages/UserPages/EmailList";
import { Menu } from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    textAlign: "left",
    top: 65,
    left: 0,
    width: "100%",
    height: "100%",
    paddingBottom: 62,
  },
  sidebar: {
    flex: 1,
    flexGrow: 1,
    overflowY: "scroll",
    borderBottom: "1px solid black",
    height: "100%",
    width: "250px",
    fontSize: "15px",
    textAlign: "left",
    listStyle: "none",
    position: "relative",
  },
});

class SettingsBar extends Component {
  constructor() {
    super();
    this.state = {
      page: "lookfeel",
      open: false,
    };
  }

  handleLogout = (event) => {
    this.props.logoutUser();
  };

  emailList = (event) => {
    this.setState({ page: event.target.id });
  };

  handleState = (event) => {
    this.setState({ page: event.target.id, open: false });
  };

  handleMenu = (event) => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    //const { classes } = this.props;
    let { page } = this.state;

    return (
      <div>
        <div style={{ position: "absolute", left: -15, top: -74 }}>
          <IconButton onClick={this.handleMenu}>
            <MenuIcon style={{ width: 25, height: 25 }} />
          </IconButton>
        </div>

        <Menu
          id="simple-menu"
          //   anchorEl={this.anchorEl}
          keepMounted
          open={this.state.open}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <MenuItem id="lookfeel" onClick={this.handleState}>
            Look & Feel
          </MenuItem>
          {/*<MenuItem id="notifications" onClick={this.handleState}>
            Notifications
          </MenuItem>*/}
          <MenuItem id="emailList" onClick={this.handleState}>
            Email List
          </MenuItem>
          <MenuItem id="privacy" onClick={this.handleState}>
            Privacy
          </MenuItem>
          <MenuItem id="changepassword" onClick={this.handleState}>
            Change Password
          </MenuItem>
        </Menu>

        <Grid item sm={8} sx={8}>
          {page === "lookfeel" && <LookFeel />}
          {/* {page === "notifications" && <Notifications />} */}
          {page === "privacy" && <PrivacySettings />}
          {page === "changepassword" && <ChangePass />}
          {page === "emailList" && <EmailList user={this.props.user} />}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  user: state.user,
  imageUrl: state.user.credentials.imageUrl,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(SettingsBar));
