import React, { Component } from "react";
import { PropTypes } from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import firebase from "../util/config";
import LoadingPage from "../util/LoadingPage";
import { removeElem, editElem } from "../util/chatHelper";

//import firebase from "./../util/config";
import { editNote, deleteNote } from "../redux/actions/userAction";

// Redux
import { connect } from "react-redux";

// Material UI
import CreateIcon from "@material-ui/icons/Create";
import ExploreIcon from "@material-ui/icons/Explore";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Divider } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";

// Import languages
import languageWords from "../Languages";
import EditText from "./EditText";

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    top: 55,
    left: 20,
    width: "100%",
    paddingRight: 40,
    height: "100%",
    paddingBottom: 225,
  },
  groupName: {
    fontSize: 18,
  },
  usersInChat: {
    position: "absolute",
    right: 40,
    marginTop: 35,
  },
  findNewGroup: {
    position: "absolute",
    right: 120,
    marginTop: 35,
  },
  chatBorder: {
    border: "1px solid black",
    width: "100%",
    height: "100%",
    position: "relative",
    overflowY: "scroll",
    marginBottom: 10,
    backgroundColor: theme.palette.backgroundColor,
  },
  chatBar: {
    position: "relative",
    left: 0,
    width: "95%",
    marginRight: 10,
  },
  sendButton: {
    width: 35,
    height: 35,
    fill: "green",
  },
  welcome: {
    marginTop: 50,
    margin: "auto",
    zIndex: -1,
    marginLeft: 20,
    marginRight: 20,
  },
  chatList: {
    textAlign: "left",
    listStyleType: "none",
    margin: 0,
    paddingLeft: 0,
    fontSize: 18,
    width: "100%",
    wordWrap: "breakWord",
    flexWrap: "wrap",
    overflowWrap: "anywhere",
  },
});
//useStyles();
class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [],
      unsubscribe: null,
      editBox: null,
    };
    this.state.messagesEndRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
    this.getNotes(this.props.currentUser.credentials.handle);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  getNotes(handle) {
    console.log(handle);
    if (handle !== null) {
      let ref = firebase
        .firestore()
        .collection("users")
        .doc(handle)
        .collection("Notes")
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
          let chat = this.state.chats;
          let changes = snapshot.docChanges();
          changes.forEach((change) => {
            if (change.type === "added") {
              chat.push({ ...change.doc.data(), id: change.doc.id });
            }
            if (change.type === "removed") {
              chat = removeElem(chat, change.doc);
            }
            if (change.type === "modified") {
              chat = editElem(chat, change.doc);
            }
          });
          this.setState({ chats: chat });
          this.setState({ unsubscribe: ref });
        });
    }
  }

  componentWillUnmount() {
    if (this.state.unsubscribe != null) this.state.unsubscribe(); //remove listener
  }
  scrollToBottom = () => {
    this.state.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  handleDeleteNote = (event) => {
    this.props.deleteNote(event);
  };
  handleEditBox = (event) => {
    this.setState({ editBox: null });
  };

  handleEditNote = (event) => {
    if (this.state.editBox !== event) this.setState({ editBox: event });
    else this.setState({ editBox: null });
    // this.props.editNote(event);
  };
  render() {
    const {
      classes,
      //UI: { loading },
      User: { notes },
      // currentUser: { chat },
    } = this.props;

    let chats = this.state.chats;

    return (
      <div
        style={{
          marginTop: 50,
          margin: "auto",
          zIndex: -1,
          marginLeft: 20,
          marginRight: 20,
        }}
        className="scroll"
      >
        <h1>{`${languageWords.welcome} TO adaptMi!`}</h1>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CreateIcon style={{ marginRight: 20 }} />
          <p style={{ textAlign: "left", fontSize: 18, maxWidth: 500 }}>
            <b>{languageWords.homePointOneBoldText}</b>
            {languageWords.homePointOneText}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ExploreIcon style={{ marginRight: 20 }} />
          <p style={{ textAlign: "left", fontSize: 18, maxWidth: 500 }}>
            <b>{languageWords.homePointTwoBoldText}</b>
            {languageWords.homePointTwoText}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PersonAddIcon style={{ marginRight: 20 }} />
          <p style={{ textAlign: "left", fontSize: 18, maxWidth: 500 }}>
            <b>{languageWords.homePointThreeBoldText}</b>
            {languageWords.homePointThreeText}
          </p>
        </div>

        {/* <Divider style={{ marginBottom: 10 }} /> */}

        <div>
          <ul className={classes.chatList}>
            {chats.length >= 0 ? (
              chats.map((item, index) => (
                <li key={index} className="convoChat">
                  <div className="convoChat__hover">
                    <Tooltip title="Edit Note" placement="top">
                      <IconButton
                        onClick={() => this.handleEditNote(index)}
                        className="button"
                      >
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Note" placement="top">
                      <IconButton
                        onClick={() => this.handleDeleteNote(item.id)}
                        className="button"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                  <div className="convoChat__no-hover">
                    <div style={{ width: "93%" }}>
                      {this.state.editBox === index ? (
                        <EditText
                          bubble={item}
                          notes={true}
                          close={this.handleEditBox}
                        />
                      ) : (

                        <div>
                          <div>{new Date(firebase.firestore.Timestamp.now().seconds*1000).toLocaleDateString()}</div>
                          <div>{item.message}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div>{languageWords.Loading}...</div>
            )}
            <div style={{ marginTop: 15 }}></div>
          </ul>
          <div ref={this.state.messagesEndRef} />
        </div>
        {this.state.chats.length < 0 && <LoadingPage chat={true} />}
      </div>
    );
  }
}

Notes.propTypes = {
  UI: PropTypes.object.isRequired,
  User: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  chat: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
  currentGroup: state.user.currentGroup,
  chat: state.user.chat,
  UI: state.UI,
  User: state.user.credentials,
});

export default connect(mapStateToProps, { deleteNote, editNote })(
  withStyles(useStyles)(Notes)
);
