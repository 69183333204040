import React, { Component } from "react";
import PropTypes from "prop-types";

// MUI - Material Ui
import withStyles from "@material-ui/core/styles/withStyles";

import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// Icon - Material ui
import EditIcon from "@material-ui/icons/Edit";


import ChatHistory from "../Component/ChatHistory";
import "../App.css";

// Import languages
import languageWords from "../Languages";

// Redux
import { connect } from "react-redux";
import {
  //logoutUser,
  uploadImage,
  getGroupInfo,
  deleteFriend,
  removeGroup,
} from "../redux/actions/userAction";

//const notes = ["hello", "hi"];

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    top: 65,
    left: 0,
    width: "100%",
    height: "100%",
    paddingBottom: 65,
  },
  profileDesc: {
    position: "relative",
    top: 125,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    textAlign: "center",
  },
  topBackground: {
    width: "100%",
    height: "200px",
    backgroundColor: "#fcecdc",
  },
  Friends: {
    left: 0,
    position: "absolute",
    width: "48%",
    height: "100%",
  },
  Groups: {
    right: 0,
    position: "absolute",
    width: "48%",
    height: "100%",
  },
  list: {
    fontSize: 20,
  },
  icon: {
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.background.default,
    },
  },
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      chatHistory: false,
      groupId: "",
      groupName: "",
      chatList: {},
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 718 });
  }

  handleImageChange = (event) => {
    const image = event.target.files[0];
    const imageData = new FormData();
    imageData.append("image", image, image.name);
    this.props.uploadImage(imageData);
  };

  handleEditPicture = (event) => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  deleteFriendButton = (args) => {
    alert(args + " has been deleted from your friends list");

    const nameDelete = args;
    const friendName = { friendName: nameDelete };

    this.props.deleteFriend(friendName);
  };

  deleteGroupButton = (args) => {
    alert(args.name + " has been deleted from your groups");

    const groupId = { groupId: args.id };

    this.props.removeGroup(groupId);
  };

  downloadChatPdf = (group) => {
    // alert(group.id);   //passed in from the onClick buttton, binding the item info
    // alert(group.name);

    this.props.getGroupInfo(group.id);
    this.setState({ groupId: group.id });
    this.setState({ groupName: group.name });

    this.setState({ chatHistory: true });

    // alert(this.state.groupId);
    // alert(group.id);

    setTimeout(
      function () {
        this.setState({ chatHistory: false });
      }.bind(this),
      5000
    );
  };

  render() {
    const {
      classes,
      //loading,
      User: { handle, imageUrl, bio, friends },
      Groups,
      //currentGroup,
      //chat,
    } = this.props;
    const isDesktop = this.state.isDesktop;

    return (
      <div className={classes.root}>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
          }}
          class="scroll"
        >
          <div className={classes.topBackground}>
            <div className={classes.profileDesc}>
              <div>
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Tooltip title="Edit profile picture" placement="top">
                      <IconButton
                        onClick={this.handleEditPicture}
                        className="button"
                      >
                        <EditIcon background="primary" />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Avatar
                    style={{
                      height: 150,
                      width: 150,
                    }}
                    src={imageUrl}
                  />
                </Badge>
                <input
                  type="file"
                  id="imageInput"
                  hidden="hidden"
                  onChange={this.handleImageChange}
                />

                <h1>{handle}</h1>
                <h1>{bio}</h1>
              </div>
            </div>
          </div>
          {isDesktop ? (
            <div
              style={{
                width: "100%",
                paddingRight: 20,
                paddingLeft: 20,
                height: "45%",
                position: "relative",
                top: 140,
              }}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className={classes.Friends}>
                  <h1>{languageWords.Friends}</h1>
                  <div
                    style={{
                      border: "2px solid black",
                      width: "100%",
                      height: "100%",
                      marginBottom: 20,
                      overflow: "auto",
                      minHeight: 175,
                      overflowY: "auto",
                      color: "primary",
                    }}
                    class="scroll"
                  >
                    <List>
                      {friends &&
                        friends.map((friend, index) => (
                          <ListItem className={classes.list} key={index}>
                            {friend.handle}

                            <Badge
                              overlap="circle"
                              style={{ position: "absolute", right: 40 }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <Tooltip title="Delete friend" placement="top">
                                  <IconButton>
                                    <HighlightOffIcon
                                      onClick={this.deleteFriendButton.bind(
                                        this,
                                        friend
                                      )}
                                    />
                                  </IconButton>
                                </Tooltip>
                              }
                            ></Badge>
                          </ListItem>
                        ))}
                    </List>
                  </div>
                </div>
                <div className={classes.Groups}>
                  <h1>{languageWords.Groups}</h1>
                  <div
                    style={{
                      border: "2px solid black",
                      width: "100%",
                      height: "100%",
                      marginBottom: 20,
                      overflowY: "auto",
                      minHeight: 175,
                    }}
                    class="scroll"
                  >
                    <List>
                      {Groups &&
                        Groups.map((group, index) => (
                          <ListItem className={classes.list} key={index}>
                            {group.name}

                            <div
                              style={{
                                position: "absolute",
                                right: 20,
                              }}
                            >
                              <Badge
                                overlap="circle"
                                style={{ marginRight: 20 }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <Tooltip title="Leave group" placement="top">
                                    <IconButton>
                                      <HighlightOffIcon
                                        // onClick={this.deleteFriendButton.bind(this, friend)}
                                        onClick={this.deleteGroupButton.bind(
                                          this,
                                          group
                                        )}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                }
                              ></Badge>

                              {/* <Badge
                                overlap="circle"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <Tooltip
                                    title="Download chat history"
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={this.downloadChatPdf.bind(
                                        this,
                                        group
                                      )}
                                      className="button"
                                    >
                                      <NoteIcon />
                                    </IconButton>
                                  </Tooltip>
                                }
                              ></Badge> */}
                            </div>
                          </ListItem>
                        ))}
                    </List>
                    {this.state.chatHistory ? (
                      <ChatHistory
                        groupName={this.state.groupName}
                        groupdId={this.state.groupdId}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            //THIS BELOW IS FOR IPAD SIZE AND SMALLER
            <div
              style={{
                width: "100%",
                paddingRight: 20,
                paddingLeft: 20,
                height: "45%",
                top: 140,
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  textAlign: "left",
                }}
              >
                <h1>Friends</h1>
                <div
                  style={{
                    position: "relative",
                    border: "2px solid black",
                    width: "100%",
                    height: "50%",
                    minHeight: 175,
                    overflowY: "scroll",
                  }}
                >
                  <List>
                    {friends &&
                      friends.map((friend, index) => (
                        <ListItem className={classes.list} key={index}>
                          {friend}
                          <Badge
                            overlap="circle"
                            style={{ position: "absolute", right: 40 }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <Tooltip title="Delete friend" placement="top">
                                <IconButton style={{ marginLeft: 20 }}>
                                  <HighlightOffIcon
                                    onClick={this.deleteFriendButton.bind(
                                      this,
                                      friend
                                    )}
                                  />
                                </IconButton>
                              </Tooltip>
                            }
                          ></Badge>
                        </ListItem>
                      ))}
                  </List>
                </div>
                <h1>{languageWords.Groups}</h1>
                <div
                  style={{
                    position: "relative",
                    border: "2px solid black",
                    width: "100%",
                    height: "50%",
                    minHeight: 175,
                    marginBottom: 30,
                    overflowY: "scroll",
                  }}
                >
                  <List>
                    {Groups &&
                      Groups.map((group, index) => (
                        <ListItem className={classes.list} key={index}>
                          {group.name}
                          <div
                            style={{
                              position: "absolute",
                              right: 20,
                            }}
                          >
                            <Badge
                              overlap="circle"
                              style={{ marginRight: 20 }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <Tooltip title="Leave group" placement="top">
                                  <IconButton>
                                    <HighlightOffIcon
                                    // onClick={this.deleteFriendButton.bind(this, friend)}
                                    />
                                  </IconButton>
                                </Tooltip>
                              }
                            ></Badge>
                            {/* <Badge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <Tooltip
                                  title="Download chat history"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={this.downloadChatPdf.bind(
                                      this,
                                      group
                                    )}
                                    className="button"
                                  >
                                    <NoteIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            ></Badge> */}
                          </div>
                        </ListItem>
                      ))}
                  </List>
                  {this.state.chatHistory ? (
                    <ChatHistory groupName={this.state.groupName} />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.user.credentials,
  loading: state.user.loading,
  Groups: state.user.groups,
  currentGroup: state.user.currentGroup,
  chat: state.user.chat,
});

const mapActionsToProps = {
  deleteFriend,
  getGroupInfo,
  uploadImage,
  removeGroup,
};

Profile.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  deleteFriend: PropTypes.func.isRequired,
  removeGroup: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  chat: PropTypes.array.isRequired,
  User: PropTypes.object.isRequired,
  Groups: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(Profile));
