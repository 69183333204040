import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// AuthRoute to not allow a user to connect to page
const VerifiedRoute = ({
  component: Component,
  authenticated,
  credentials,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      props =>
        authenticated !== true ? <Redirect to="/" /> : <Component {...props} credentials={credentials}/>
    }
  />
);

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
  credentials: state.user.credentials
});

VerifiedRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  credentials: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(VerifiedRoute);
