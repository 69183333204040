import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { setLanguage } from "../redux/actions/userAction";

const LanguageSwitch = () => {
    const languageValue = useSelector(state => state.language.language);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        dispatch(setLanguage(event.target.value));
    }

    return (
        <RadioGroup aria-label="language" name="language" value={languageValue} onChange={handleChange}>
            <FormControlLabel value="en" control={<Radio color="primary"/>} label="English" />
            <FormControlLabel value="de" control={<Radio color="primary" />} label="Deutsche" />
            <FormControlLabel value="it" control={<Radio color="primary" />} label="Italiano" />
            <FormControlLabel value="sp" control={<Radio color="primary"/>} label="Español" />
            <FormControlLabel value="ar" control={<Radio color="primary" />} label="العربية" />
        </RadioGroup>
    );
}

export default LanguageSwitch;