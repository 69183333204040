import React, { Component } from "react";
import { PropTypes } from "prop-types";

// Redux
import { changePassword } from "./../redux/actions/userAction";
import { connect } from "react-redux";

// MUI - Material UI
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Import languages
import languageWords  from '../Languages';

//import Container from "@material-ui/core/Container";
import { CircularProgress } from "@material-ui/core";

const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}))(TextField);

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    left: "280px",
    textAlign: "left",
    paddingRight: "280px",
    paddingBottom: "66px",
    height: "100%",
    width: "100%",
  },
  paper: {
    marginTop: "20",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginRight: "auto",
    marginLeft: "auto",
  },
  avatar: {
    margin: "20px auto auto auto",
    backgroundColor: "#00A8E8",
    marginBottom: 20,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  submit: {
    margin: "20px auto auto auto",
    position: "relative",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  customError: {
    color: theme.palette.error,
    fontSize: "0.8rem",
    textAlign: "left",
    margin: "20px auto 20px auto",
  },
  progress: {
    position: "absolute",
  },
  checkBox: {
    textAlign: "left",
    marginTop: "10px",
  },
  links: {
    margin: "10px auto 20px auto",
  },
  loginLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  iphone: {
    margin: "auto",
    textAlign: "center",
  },
});

class ChangePass extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      confirmPassword: "",
      errors: {},
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  handleSumbit = (event) => {
    event.preventDefault();
    const newUserPass = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };

    this.props.changePassword(newUserPass, this.props.history);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 600 });
  }

  render() {
    const isDesktop = this.state.isDesktop;

    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {isDesktop ? (
          <div className={classes.root}>
            <div
              style={{
                overflowY: "auto",
                position: "relative",
                height: "100%",
              }}
            >
              <Avatar className={classes.avatar} />
              <Typography style={{ textAlign: "center" }} variant="h4">
                {languageWords.ChangePassword}
              </Typography>

              <form
                className={classes.form}
                noValidate
                onSubmit={this.handleSumbit}
              >
                <CssTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={languageWords.NewPassword}
                  type="password"
                  id="password"
                  helperText={errors.password}
                  error={errors.password ? true : false}
                  value={this.state.password}
                  onChange={this.handleChange}
                  style={{
                    width: "80%",
                    position: "relative",
                  }}
                />

                <CssTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label={languageWords.ConfirmPassword}
                  type="password"
                  id="confirmPassword"
                  helperText={errors.confirmPassword}
                  error={errors.confirmPassword ? true : false}
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                  style={{
                    width: "80%",
                    position: "relative",
                  }}
                />

                {errors.general && (
                  <Typography
                    variant="body2"
                    color="error"
                    className={classes.customError}
                  >
                    {errors.general}
                  </Typography>
                )}

                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                  style={{
                    width: "40%",
                    position: "relative",
                    minWidth: 189,
                    marginBottom: 30,
                  }}
                >
                  {languageWords.ChangePassword}
                  {loading && (
                    <CircularProgress size={30} className={classes.progress} />
                  )}
                </Button>
              </form>
            </div>
          </div>
        ) : (
          <div className={classes.iphone}>
            <Avatar className={classes.avatar} />
            <Typography style={{ textAlign: "center" }} variant="h4">
              {languageWords.ChangePassword}
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={this.handleSumbit}
            >
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={languageWords.NewPassword}
                type="password"
                id="password"
                helperText={errors.password}
                error={errors.password ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
                style={{
                  width: "80%",
                  position: "relative",
                }}
              />

              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={languageWords.ConfirmPassword}
                type="password"
                id="confirmPassword"
                helperText={errors.confirmPassword}
                error={errors.confirmPassword ? true : false}
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                style={{
                  width: "80%",
                  position: "relative",
                }}
              />

              {errors.general && (
                <Typography
                  variant="body2"
                  color="error"
                  className={classes.customError}
                >
                  {errors.general}
                </Typography>
              )}

              <br />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
                style={{
                  width: "40%",
                  position: "relative",
                  minWidth: 189,
                  marginBottom: 30,
                }}
              >
                {languageWords.ChangePassword}
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

ChangePass.propTypes = {
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
});

const mapActionsToProps = {
  changePassword,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(ChangePass));
