import React, { Component } from "react";
// MUI - Material UI

const useStyles = (theme) => ({
  root: {},
});
useStyles();
class QuestionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isDesktop: false,
      image1: false,
      image2: false
    };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.activeImage1 =  this.activeImage1.bind(this);
    this.activeImage2 = this.activeImage2.bind(this)
  }


  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 466 });
  }

  activeImage1(){
    this.props.onChangeValue1();
    this.setState({image1: true, image2: false});
  }
  activeImage2(){
    this.props.onChangeValue2();
    this.setState({image1: false, image2: true});
  }
  render() {
    //const classes = this.props;
    const isDesktop = this.state.isDesktop;
    return (
      <div>
       
        {isDesktop ? (
          
          <div style={{ height: "100%", width: "100%" }}>
            <div style={{ display: "flex" }}>
              <div>
                <img
                  alt="questions 1"
                  className={this.state.image1 ? "column columnActive": "column"}
                  active={true}
                  src={this.props.firstQuestion.imageUrl}
                  style={{ left: 0, marginLeft: 2 }}
                  value="test"
                  onClick={this.activeImage1}
                />
                <h2 className="category_one">
                  {this.props.firstQuestion.name}
                </h2>
              </div>

              <div >
                <img
                  alt="question 2"
                  className={this.state.image2 ? "column columnActive": "column"}
                  value="test"
                  src={this.props.secondQuestion.imageUrl}
                  onClick={this.activeImage2}
                  style={{ right: 0, marginRight: 2 }}
                />
                <h2 className="category_two">
                  {this.props.secondQuestion.name}
                </h2>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ height: "100%", width: "100%" }}>
            <div
              style={{
                height: "100%",
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  width: "90%",
                  height: "30%",
                  margin: "auto",
                  zIndex: 5,
                  padding: "1%",
                  position: "relative",
                }}
              >
                <img
                  alt="questions 1"
                  className="column_iphone"
                  src={this.props.firstQuestion.imageUrl}
                  value="test"
                  onClick={this.props.onChangeValue1}
                />
              </div>
              <h3 style={{ maxWidth: "90%" }}>
                {this.props.firstQuestion.name}
              </h3>
              <div
                style={{
                  width: "90%",
                  height: "30%",
                  margin: "auto",
                  zIndex: 5,
                  padding: "1%",
                  position: "relative",
                }}
              >
                <img
                  alt="question 2"
                  className="column_iphone"
                  value="test"
                  src={this.props.secondQuestion.imageUrl}
                  onClick={this.props.onChangeValue2}
                />
              </div>
              <h3 style={{ maxWidth: "90%" }}>
                {this.props.secondQuestion.name}
              </h3>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default QuestionCard;
