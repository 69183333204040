import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  DARK_THEME,
  LIGHT_THEME,
  LOADING_USER,
  SET_GROUP,
  SET_GROUPCHAT,
  LANGUAGE_CHANGE,
  SET_NOTES,
} from "./../types";
import axios from "axios";

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/login", userData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      var start = new Date().getTime();
      var end = start;
      while (end < start + 2000) {
        end = new Date().getTime();
      }

      history.go(0);
    })
    .catch((err) => {
      if (err.response != null) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .get("/user")
    .then((res) => {
      dispatch({
        type: SET_USER, //action.type
        payload: res.data, // action.payload
      });
    })
    .catch((err) => console.log(err));
};

export const signupUser = (newUserData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/signup", newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      var start = new Date().getTime();
      var end = start;
      while (end < start + 2000) {
        end = new Date().getTime();
      }
      history.go(0);
    })
    .catch((err) => {
      if (err.response != null) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const verifyEmail = (newUserData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/verifyemail")
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      if (err.response != null) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const forgotPassword = (email, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/forgotpassword", email)
    .then(() => {
      dispatch({ type: CLEAR_ERRORS });
      history.push("/");
    })
    .catch((err) => {
      if (err.response != null) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const changePassword = (newPass, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/changepassword", newPass)
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
      history.push("/profile");
    })
    .catch((err) => {
      if (err.response != null) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  let config = {
    headers: {
      "content-type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  };
  axios
    .post("/user/image", formData, config)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => {
      console.log("big time erros");
      console.log(err);
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("FBIdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};

export const setTheme = (theme) => (dispatch) => {
  var themes = !theme.theme;
  if (themes === true) dispatch({ type: DARK_THEME, payload: true });
  else dispatch({ type: LIGHT_THEME, payload: false });
};

export const setLanguage = (language) => (dispatch) => {
  dispatch({ type: LANGUAGE_CHANGE, payload: language });
};

export const addFriend = (friendId) => (dispatch) => {
  //alert("made it to add friend user action");
  // console.log("made it to addfriend user action");

  dispatch({ type: LOADING_UI });

  axios
    .post("/user/friend", friendId)
    .then((res) => {
      dispatch(getUserData());
      // console.log("added friend successfully user action");
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log("Error ADDING Friend user action");
      console.log(err);
    });
};

export const deleteFriend = (friendName) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/user/deletefriend", friendName)
    .then((res) => {
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log("Error DELETING Friend");
      console.log(err);
    });
};

export const addNote = (noteData) => (dispatch) => {

  dispatch({ type: LOADING_UI });
  axios
    .post("/addnote", noteData)
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: CLEAR_ERRORS });
    });
};
export const editNote = (id, noteData) => (dispatch) => {

  dispatch({ type: LOADING_UI });
  console.log("test",  { noteId: id, noteData: noteData})
  axios
    .post("/editnote", { noteId: id, noteData})
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: CLEAR_ERRORS });
    });
};

export const deleteNote = (noteData) => (dispatch) => {

  dispatch({ type: LOADING_UI });
    
  axios
    .post("/deletenote", {noteId: noteData})
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: CLEAR_ERRORS });
    });
};

export const subscribeMail = (userData) => (dispatch) => {
  // console.log("made it to subscribeMail user action");

  dispatch({ type: LOADING_UI });
  let userParam = {
    userId: userData.credentials.userId,
    email: userData.credentials.email,
  };

  axios
    .post("user/subscribeEmailList", userParam)
    .then(() => {
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const unSubscribeMail = (userData) => (dispatch) => {
  // console.log("made it to unSubscribeMail user action");

  dispatch({ type: LOADING_UI });
  let userParam = {
    userId: userData.credentials.userId,
    email: userData.credentials.email,
  };

  axios
    .post("user/unSubscribeEmailList", userParam)
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
    });
};

//Groups

export const getGroupInfo = (groupId, history) => (dispatch) => {
  // dispatch({ type: LOADING_UI });
  let groupInfo = {
    id: groupId,
  };

  // console.log(groupInfo);

  axios
    .post("/user/group", groupInfo)
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: SET_GROUP, //action.type
        payload: res.data, // action.payload
      });
      dispatch(getGroupChat(groupId));
      dispatch({ type: CLEAR_ERRORS });
      history.push("/");
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: CLEAR_ERRORS });
    });
};
export const getNotesReset = (groupId) => (dispatch) => {
  dispatch({ type: SET_NOTES });
};

export const getGroupChat = (groupId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  let groupInfo = {
    id: groupId,
  };

  // console.log(groupInfo);
  axios
    .post("/user/groupchat", groupInfo)
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: SET_GROUPCHAT, //action.type
        payload: res.data, // action.payload
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: CLEAR_ERRORS });
    });
};

export const createGroupChatbubble = (bubble) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/user/createchat", bubble)
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({ type: CLEAR_ERRORS });
    });
};

export const editChatbubble = (id, chatId,message) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  const bubble = {id, chatId, message}
  axios
  .post("/user/editchat", bubble)
  .then((res) => {
    dispatch({ type: CLEAR_ERRORS });
  })
  .catch((err) => {
    dispatch({ type: CLEAR_ERRORS });
  });
}

export const deleteChatbubble = (id, chatId) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  const bubble = {id, chatId}
  console.log(bubble)
  axios
  .post("/user/deletechat", bubble)
  .then((res) => {
    dispatch({ type: CLEAR_ERRORS });
  })
  .catch((err) => {
    dispatch({ type: CLEAR_ERRORS });
  });
}

export const newGroup = (groupCat) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/newgroup", groupCat)
    .then((res) => {
      console.log("group placement successful");
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log("Error adding user to group");
      console.log(err);
    });
};

export const removeGroup = (groupId) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post("/deletegroup", groupId)
    .then((res) => {
      dispatch(getUserData());
      console.log("group deletion successful");
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log("Error deleting group");
      console.log(err);
    });
};
