import React from "react";
import Link from "react-router-dom/Link";

import Typography from "@material-ui/core/Typography";
// Import languages
import languageWords  from '../Languages';
// import { Privacy } from './../Pages/Privacy';
import { withStyles } from "@material-ui/core/styles";

const useStyles = (theme) => ({ 
  privacy: {
    color: theme.palette.secondary.light, 
    textDecoration: "none", 
    marginBottom: 40
  },
})
function Copyright(props) {
  return (
    <div>
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      
    >
      {`${languageWords.copyright} © `}
      adaptMi {new Date().getFullYear()}
      {"."}
    </Typography>
    </div>
  );
}

export default (withStyles(useStyles)(Copyright));

