import React, { Component } from "react";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// Import languages
import languageWords  from '../../Languages'

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    left: "280px",
    textAlign: "left",
    paddingRight: "280px",
    paddingBottom: "66px",
    height: "100%",
    width: "100%",
  },
  download: {
    overflowY: "auto",
    left: "280px",
    textAlign: "left",
    //marginTop: "210px",
    color: "inherit",
    marginBottom: 100,
  },
  iphone: {
    margin: "auto",
  },
});

class PrivacySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({
      isDesktop: window.innerWidth > 600,
    });
  }

  linkAlert = () => {
    navigator.clipboard.writeText("https://adaptmi.io/register");
    alert("Link Copied!");
  };

  render() {
    const { classes } = this.props;
    const isDesktop = this.state.isDesktop;
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {isDesktop ? (
          <div className={classes.root}>
            <div
              style={{
                position: "relative",
                overflowY: "auto",
                height: "100%",
                width: "100%",
              }}
            >
              <h1>{languageWords.PrivacyPolicy}</h1>
              <h3>{languageWords.LastUpdated}: 02/15/2020</h3>
              <p style={{ maxWidth: "80%" }}>
                {languageWords.PrivacyPolicyTextOne} adaptMi.
                {languageWords.PrivacyPolicyTextTwo} adaptMi@gmail.com. 
                {languageWords.PrivacyPolicyTextThree} 'adaptMi', 
                {languageWords.PrivacyPolicyTextFour}
              </p>

              <p className={classes.download}>
              {languageWords.PrivacyPolicyTextFive}
                <a
                  href="/adaptMiPrivacyPolicy.pdf"
                  download
                  style={{ color: "inherit" }}
                >
                  {languageWords.here}
                </a>
                .
              </p>

              <h1>{languageWords.Share}</h1>

              <TextField
                disabled
                id="outlined-disabled"
                ref={(textarea) => (this.textArea = textarea)}
                defaultValue="https://adaptmi.io/register"
                variant="outlined"
                style={{ marginBottom: 20 }}
              />

              <div className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.linkAlert}
                  style={{ marginBottom: 30 }}
                >
                  {languageWords.CopyLink}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.iphone}>
            <div>
              <h1>Privacy Policy</h1>
              <h3>Last Updated: 02/15/2020</h3>
              <p>
                Thank you for choosing to be part of our community at adaptMi.
                We are committed to protecting your personal information and
                your right to privacy. If you have any questions or concerns
                about our notice, or our practices with regards to your personal
                information, please contact us at adaptMi@gmail.com. When you
                visit our website 'adaptMi', and use our services, you trust us
                with your personal information. We take your privacy very
                seriously.
              </p>
            </div>

            <div style={{ marginBottom: 40 }}>
              <p>
                If you would like to download our privacy policy please click{" "}
                <a
                  href="/adaptMiPrivacyPolicy.pdf"
                  download
                  style={{ color: "inherit" }}
                >
                  here
                </a>
                .
              </p>
            </div>

            <h1>Share This Link With Your Friends!</h1>
            <div>
              <div>
                <form className={classes.send} noValidate autoComplete="off">
                  <div>
                    <TextField
                      disabled
                      id="outlined-disabled"
                      ref={(textarea) => (this.textArea = textarea)}
                      defaultValue="https://adaptmi.io/register"
                      variant="outlined"
                      style={{ marginBottom: 20 }}
                    />
                  </div>
                </form>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.linkAlert}
                  style={{ marginBottom: 30 }}
                >
                  Copy Link
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(useStyles)(PrivacySettings);
