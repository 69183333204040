import React from 'react';

const LoadingPopup = ({ component: Component }) => (
  <div className="loading_popup">
    <div className="loading_popup_inner">
      <Component />
    </div>
  </div>
);

export default LoadingPopup;
