import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/Link";
import pink from "../Images/pink.png";
import "../App.css";

// Components
import Copyright from "../Component/Footer";
import PopupBox from "./../Component/PopupBox";
import { LoadingPage } from "./../util/LoadingPage";

// MUI- Material UI
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CircularProgress, Paper } from "@material-ui/core";

// Redux items
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userAction";
import LoadingPopup from "./../Component/LoadingPopup";

// Import languages
import languageWords from "../Languages";

// TODO: Play around with these colors when actice/focused for the user
const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}))(TextField);

const useStyles = (theme) => ({
  image: {
    paddingTop: "-66px",
    minHeight: "100vh",
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    marginTop: "20px",
    position: "relative",
    paddingTop: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: "20px auto auto auto",
    height: 80,
    width: 80,
    // backgroundColor: "#00A8E8",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  submit: {
    marginTop: "40",
    position: "relative",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  customError: {
    color: theme.palette.error,
    fontSize: "0.8rem",
    textAlign: "left",
    margin: "20px auto 20px auto",
  },
  progress: {
    position: "absolute",
  },
  checkBox: {
    float: "left",
    textAlign: "left",
  },
  links: {
    margin: "10px auto 20px auto",
  },
  link: {
    color: theme.palette.secondary.light,
    textDecoration: "none",
  },
});

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  handleSumbit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData, this.props.history);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      classes,
      UI: { loading }, //listening to state of UI
    } = this.props;
    const { errors } = this.state;
    return (
      <Grid container spacing={0} component="main">
        <Grid item xs={false} sm={8} className={classes.image} />
        <Grid item xs={12} sm={4}>
          {loading && <LoadingPopup loading={true} component={LoadingPage} />}
          <Container className={classes.paper}>
            <Avatar className={classes.avatar} src={pink} alt="logo" />

            <Typography variant="h4" className={classes.pageTitle}>
              adaptMi
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={this.handleSumbit}
            >
              <CssTextField
                id="email"
                label={languageWords.email}
                type="email"
                name="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="email"
                autoFocus
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChange}
              />

              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={languageWords.password}
                type="password"
                id="password"
                autoComplete="current-password"
                helperText={errors.password}
                style={{ marginBottom: 20 }}
                error={errors.password ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
              />
              {errors.general && (
                <Typography
                  variant="body2"
                  color="error"
                  className={classes.customError}
                >
                  {errors.general}
                </Typography>
              )}
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="secondary" />}
              label={languageWords.Remember}
              className={classes.checkBox}
            /> */}
              <br />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                {languageWords.login}
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
            </form>
            <Grid container className={classes.links}>
              <Grid item xs>
                <Link
                  to="/forgotpassword"
                  className={classes.link}
                  variant="body2"
                >
                  {languageWords.forgotPassword}
                </Link>
              </Grid>
              <Grid item>
                <Link to="/register" className={classes.link} variant="body2">
                  {languageWords.noAccount}
                </Link>
              </Grid>
            </Grid>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(Login));
