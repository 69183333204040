import {
  LOADING_UI,
  SET_QUESTIONS,
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_NEWQUESTIONS,
  LOADING_DATA,
  LOADING_CLEAR,
  ADD_GROUP,
  LISTENER_ADD,
  REMOVE_LISTENER,
  REMOVE_GROUP
} from "./../types";

import firebase from "../../util/config";

import axios from "axios";

export const getQuestions = (question, qNumber, history) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .post("/user/questions", question)
    .then((res) => {
      dispatch({
        type: SET_QUESTIONS, //action.type
        payload: res.data, // action.payload
        number: qNumber,
      });
      dispatch({ type: LOADING_CLEAR });
    })
    .catch((err) => {
      if (err.response != null) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const getGroupList = (handle) => (dispatch) =>  {
  let ref = firebase
  .firestore()
  .collection("users")
  .doc(handle)
  .collection("Groups")
  .onSnapshot((snapshot) => {
    let changes = snapshot.docChanges();
    changes.forEach((change) => {
      if (change.type === "added") {
        dispatch({
          type: ADD_GROUP,
          payload: change.doc.data(),
        });
      }
      if(change.type === "removed"){
        dispatch({
          type: REMOVE_GROUP,
          payload: change.doc.data(),
        });
      }
    });
  });
  dispatch({type: LISTENER_ADD, payload: ref})
  // axios
  //   .get("/getGroupListener")
  //   .then((res) => {
  //     console.log(res)
  //     console.log(res.ref);
  //     res.data.ref.onSnapshot((snapshot) => {
  //       let changes = snapshot.docChanges();
  //       changes.forEach((change) => {
  //         if (change.type === "added") {
  //           dispatch({
  //             type: ADD_GROUP,
  //             payload: change.doc.data(),
  //           });
  //         }
  //         if(change.type === "removed"){
  //           dispatch({
  //             type: REMOVE_GROUP,
  //             payload: change.doc.data(),
  //           });
  //         }
  //       });
  //     });
  //   })
  //   .catch((err) => {
  //     console.log("GroupListener error: ", err)
  //     // if (err.response != null) {
  //     //   dispatch({
  //     //     type: SET_ERRORS,
  //     //     payload: err.response.data,
  //     //   });
  //     // }
  //   });
  

}

export const clearQuestions = () => (dispatch)=> {
  dispatch({type: SET_NEWQUESTIONS})
}
export const removeGroupList = () => (dispatch) =>  {
  console.log("removing list")
  dispatch({type: REMOVE_LISTENER});
}

export const createQuestion = (question, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/admin/createquestion", question)
    .then((res) => {
      console.log(res.data.message);
      dispatch(
        uploadQuestionImage(question.imageData, res.data.id, question.qCat)
      );
      dispatch({ type: CLEAR_ERRORS });
      history.push("/");
    })
    .catch((err) => {
      if (err.response != null) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const uploadQuestionImage = (formData, docId, qCat) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  let config = {
    headers: {
      "content-type": `multipart/form-data; boundary=${formData._boundary}`,
      id: docId,
      qcat: qCat,
    },
  };
  axios
    .post("/admin/createquestionimage", formData, config)
    .then((res) => {
      console.log(res.data.message);
    })
    .catch((err) => {
      console.log("big time erros");
      console.log(err);
    });
};

