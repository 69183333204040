import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/Link";
import pink from "../Images/pink.png";

// Components
import Copyright from "../Component/Footer";

// MUI- Material UI
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CircularProgress } from "@material-ui/core";
import Recaptcha from "react-google-invisible-recaptcha";

// Redux items
import { connect } from "react-redux";
import { signupUser } from "../redux/actions/userAction";

// Import languages
import languageWords from "../Languages";

// TODO: Play around with these colors when actice/focused for the user
const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}))(TextField);

const useStyles = (theme) => ({
  image: {
    paddingTop: "0px",
    minHeight: "100vh",
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    marginTop: "20px",
    position: "relative",
    paddingTop: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: "20px auto auto auto",
    height: 80,
    width: 80,
    // backgroundColor: "#00A8E8",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  submit: {
    margin: "20px auto auto auto",
    position: "relative",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  customError: {
    color: theme.palette.error,
    fontSize: "0.8rem",
    textAlign: "left",
    margin: "20px auto 20px auto",
  },
  progress: {
    position: "absolute",
  },
  checkBox: {
    textAlign: "left",
    marginTop: "10px",
  },
  links: {
    margin: "10px auto 20px auto",
  },
  loginLink: {
    color: theme.palette.secondary.light,
    textDecoration: "none",
  },
});

class Register extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      handle: "",
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  handleSumbit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      handle: this.state.handle,
      eula: this.state.eula,
    };

    this.props.signupUser(newUserData, this.props.history);
  };

  handleChange = (event) => {
    if (event.target.name === "eula") {
      if (this.state.eula) {
        this.setState({
          [event.target.name]: false,
          errors: { eula: "You must accept terms of service." },
        });
      } else {
        this.setState({
          [event.target.name]: true,
          errors: { eula: false },
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  sendMessage = () => {
    this.recaptcha.execute();
  };
  onResolved = () => {
    this.setState({ messageSent: true });
  };
  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    return (
      <Grid container spacing={0} component="main" /*maxWidth="xs"*/>
        <Grid item xs={0} sm={8} className={classes.image} />
        <Grid item xs={12} sm={4}>
          <Container className={classes.paper}>
            <Avatar className={classes.avatar} src={pink} alt="logo" />
            <Typography variant="h4" className={classes.pageTitle}>
              {languageWords.SignUp}
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={this.handleSumbit}
            >
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="handle"
                label={languageWords.Username}
                type="handle"
                id="handle"
                helperText={errors.handle}
                error={errors.handle ? true : false}
                value={this.state.handle}
                onChange={this.handleChange}
              />

              <CssTextField
                id="email"
                label={languageWords.email}
                type="email"
                name="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChange}
              />

              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={languageWords.password}
                type="password"
                id="password"
                helperText={errors.password || errors.complexPassword}
                error={errors.password || errors.complexPassword ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
              />

              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={languageWords.ConfirmPassword}
                type="password"
                id="confirmPassword"
                helperText={errors.confirmPassword}
                error={errors.confirmPassword ? true : false}
                value={this.state.confirmPassword}
                onChange={this.handleChange}
              />
              <FormControlLabel
                style={{ float: "left" }}
                control={
                  <Checkbox
                    value="allowExtraEmails"
                    color="secondary"
                    required
                    name="eula"
                    helperText={this.state.eula}
                    error={this.state.eula ? true : false}
                    onChange={this.handleChange}
                  />
                }
                label=""
                className={classes.checkBox}
              />
              <span
                style={{
                  float: "left",
                  paddingTop: "20px",
                  marginLeft: "-15px",
                }}
              >
                <a style={{ color: "#ffff" }} href="/privacy">
                  {languageWords.PrivacyTermsAndConditions}
                </a>
              </span>
              {errors.general && (
                <Typography
                  variant="body2"
                  color="error"
                  className={classes.customError}
                >
                  {errors.general}
                </Typography>
              )}
              <FormControlLabel
                control={
                  <Checkbox value="allowExtraEmails" color="secondary" />
                }
                label={languageWords.viaEmail}
                className={classes.checkBox}
              />
              <br />
              {errors.general && (
                <Typography
                  variant="body2"
                  color="error"
                  className={classes.customError}
                >
                  {errors.general}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                {languageWords.SignUp}
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
            </form>
            <Grid container justify="flex-end" className={classes.links}>
              <Grid Item>
                <Link to="/login" variant="body2" />
                <Grid item>
                  <Link
                    to="/login"
                    className={classes.loginLink}
                    variant="body2"
                    color="secondary"
                  >
                    {languageWords.haveAccount}
                  </Link>
                </Grid>
                <Recaptcha
                  ref={(ref) => (this.recaptcha = ref)}
                  sitekey="6Lfoes4UAAAAAIvfJtCNkios1FZnN6WUVvhxsaDF"
                  onResolved={this.onResolved}
                />
              </Grid>
            </Grid>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </Grid>
      </Grid>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, { signupUser })(
  withStyles(useStyles)(Register)
);
