import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import IconButton from "@material-ui/core/IconButton";
import GroupIcon from "@material-ui/icons/Group";

// Redux items
import { connect } from "react-redux";
import { addFriend } from "../redux/actions/userAction";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  list: {
    width: 250,
    height: "100%",
    paddingBottom: 80,
  },
  fullList: {
    width: "auto",
  },
});

function UsersInChatTab(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });
  let usersChat = props.currentGroup.users;
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    usersChat = props.currentGroup.users.users;
    setState({ ...state, [anchor]: open });
  };
  const addFriendButton = (args) => {
    alert(args.name + " has been added to your friends list");

    const idAdd = args.id;

    const friendId = { friendId: idAdd };

    props.addFriend(friendId);
  };

  //TODO: Change up the mapping function to a separate file
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <h1 style={{ textAlign: "center" }}>Users In Chat</h1>
      <Divider />
      <div style={{ height: "100%", overflowY: "auto" }} class="scroll">
        <List>
          {usersChat &&
            props.user.currentGroup.users.users.map((user) => (
              <ListItem key={user.name}>
                <ListItemIcon>
                  {user.id === props.user.credentials.userId ? null : (
                    <IconButton>
                      <PersonAddIcon
                        onClick={addFriendButton.bind(this, user)}
                      />
                    </IconButton>
                  )}
                </ListItemIcon>
                {user.id === props.user.credentials.userId ? (
                  <ListItemText
                    style={{ color: "#7688d4" }}
                    primary={user.name}
                  />
                ) : (
                  <ListItemText primary={user.name} />
                )}
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleDrawer("right", true)}
        >
          <GroupIcon />
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

UsersInChatTab.propTypes = {
  classes: PropTypes.object.isRequired,
  addFriend: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentGroup: state.user.currentGroup,
});
export default connect(mapStateToProps, { addFriend })(UsersInChatTab);
