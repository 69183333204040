import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

export const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#7688d4", //'#003459',
    },
    secondary: {
      light: "#4a4c51",
      main: "#FAFAFA",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FAFAFA",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#2E3B55", //'#003459',
    },
    secondary: {
      light: "#FFFFFF",
      main: "#4a4c51",
      dark: "",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#2C2F33",
    },
  },
  typography: {
    useNextVariants: true,
  },
  // paper: {
  //   marginTop: '20',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   textAlign: 'center',
  // },
  // avatar: {
  //   margin: '20px auto 20px auto',
  //   backgroundColor: '#00A8E8',
  // },
  // form: {
  //   width: '100%', // Fix IE 11 issue.
  //   textAlign: 'center'
  // },
  // submit: {
  //   marginTop: '20',
  //   position: 'relative'
  // },
  // pageTitle:{
  //     margin: '20px auto 20px auto'
  // },
  // customError:{
  //     color: red.A400,
  //     fontSize: '0.8rem',
  //     textAlign: 'center',
  //     margin: '20px auto 20px auto'
  // },
  // progress:{
  //     position: 'absolute'
  // }
});

export default darkTheme;
