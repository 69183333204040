import React, { Component } from "react";

import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/userAction";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../App.css";

// Pages
import LookFeel from "../Pages/UserPages/LookFeel";
//import Notifications from "../Pages/UserPages/Notifications";
import ChangePass from "../Pages/ChangePass";
import PrivacySettings from "../Pages/UserPages/PrivacySettings";
import EmailList from "../Pages/UserPages/EmailList";

// Import languages
import languageWords  from '../Languages';

const useStyles = (theme) => ({
  root: {
    position: "fixed",
    textAlign: "left",
    top: 65,
    left: 0,
    width: "100%",
    height: "100%",
    paddingBottom: 62,
  },
  sidebar: {
    flex: 1,
    flexGrow: 1,
    overflowY: "scroll",
    borderBottom: "1px solid black",
    height: "100%",
    width: "250px",
    fontSize: "15px",
    textAlign: "left",
    listStyle: "none",
    position: "relative",
  },
});

class SettingsBar extends Component {
  constructor() {
    super();
    this.state = {
      page: "lookfeel",
    };
  }

  handleLogout = (event) => {
    this.props.logoutUser();
  };

  emailList = (event) => {
    this.setState({ page: event.target.id });
  };

  handleState = (event) => {
    this.setState({ page: event.target.id });
  };

  render() {
    const { classes } = this.props;
    let { page } = this.state;
    return (
      <Grid container spacing={0} className={classes.root}>
        <Grid item sm={4} xs={4} style={{ height: "100%" }}>
          <Paper
            style={{
              flex: 1,
              flexGrow: 1,
              overflowY: "scroll",
              borderBottom: "1px solid black",
              height: "100%",
              width: "250px",
              fontSize: "15px",
              textAlign: "left",
              listStyle: "none",
              position: "relative",
            }}
            class="scroll"
          >
            <MenuList>
              <h2 style={{ textAlign: "center" }}>{languageWords.Settings} </h2>
              <MenuItem id="lookfeel" name="email" onClick={this.handleState}>
                {languageWords.LookFeel}
              </MenuItem>
              {/* <MenuItem id="notifications" onClick={this.handleState}>
                Notifications
              </MenuItem> */}
              <MenuItem id="emailList" onClick={this.emailList}>
                {languageWords.EmailList}
              </MenuItem>
              <MenuItem id="privacy" onClick={this.handleState}>
                {languageWords.privacy}
              </MenuItem>
              <MenuItem id="changepassword" onClick={this.handleState}>
                {languageWords.ChangePassword}
              </MenuItem>
            </MenuList>
          </Paper>
        </Grid>
        <Grid item sm={8} sx={8} style={{ width: "100%", height: "100%" }}>
          {page === "lookfeel" && <LookFeel />}
          {/* {page === "notifications" && <Notifications />} */}
          {page === "privacy" && <PrivacySettings />}
          {page === "changepassword" && <ChangePass />}
          {page === "emailList" && <EmailList user={this.props.user} />}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  user: state.user,
  imageUrl: state.user.credentials.imageUrl,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(SettingsBar));
