import React, { Component } from "react";

// Redux
import { unSubscribeMail, subscribeMail } from "../../redux/actions/userAction";
import { connect } from "react-redux";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// Import languages
import languageWords  from '../../Languages'

import EmailIcon from '@material-ui/icons/Email';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = (theme) => ({
  center: {
    position: "fixed",
    right: "600px",
  },
  root: {
    position: "fixed",
    left: "280px",
    textAlign: "left",
    paddingRight: "280px",
    paddingBottom: "66px",
    height: "100%",
    width: "100%",
  },
  language: {
    position: "fixed",
    left: "280px",
    textAlign: "left",
  },
  iphone: {
    margin: "auto",
    textAlign: "center",
  },
});

class EmailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 600 });
  }

  handleChange = (name) => (event) => {
    console.log("event.target.checked ->", event.target.checked);
    console.log("for ->", this.props.user.credentials);
    if (event.target.checked === true) {
      //call subscribe
      this.props.subscribeMail(this.props.user);
      localStorage.emailListStatus = JSON.stringify({value:1,userId:this.props.user.credentials.userId});
    } else {
      //call unsubscribe
      this.props.unSubscribeMail(this.props.user);
      localStorage.emailListStatus = JSON.stringify({value:0,userId:this.props.user.credentials.userId});
    }
    this.setState({ checked: event.target.checked });
  };

  render() {
    const isDesktop = this.state.isDesktop;
    const { classes } = this.props;
    let isUsersInEmailList = false;
    if(localStorage.emailListStatus && typeof localStorage.emailListStatus != 'undefined'){
        let emailListStatus = JSON.parse(localStorage.emailListStatus);
        if(emailListStatus.userId === this.props.user.credentials.userId && emailListStatus.value == 1){
          isUsersInEmailList = true;
        }
        else{
          isUsersInEmailList = false;
        }
    }
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {isDesktop ? (
          <div className={classes.root}>
            <div
              style={{
                position: "relative",
                overflowY: "auto",
                height: "100%",
                width: "100%",
              }}
            >
              <h1>{languageWords.EmailList}</h1>
              <div>
                <h3>{languageWords.Subscription}</h3>
                <div style={{ display: "flex" }}>
                  <MailOutlineIcon style={{ marginTop: "5px" }} />
                  <Switch 
                    checked={isUsersInEmailList ||  this.state.checked}
                    onChange={this.handleChange("checkedB")} //handleChange("checkedB")
                    value="checkedB"
                    color="#000000"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <EmailIcon style={{ marginTop: "5px" }}></EmailIcon>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.iphone}>
            <h1>{languageWords.EmailList}</h1>
            <div>
              <h3>{languageWords.Subscription}</h3>
              <div>
              <MailOutlineIcon style={{ marginTop: "5px" }} />
                <Switch
                  checked={this.state.checked}
                  onChange={this.handleChange("checkedB")} //handleChange("checkedB")
                  value="checkedB"
                  color="#000000"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <EmailIcon style={{ marginTop: "5px" }}></EmailIcon>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state,ownProps) => ({
  ...state,
  user:ownProps.user
});
const mapActionsToProps = {
  unSubscribeMail,
  subscribeMail,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(EmailList));
