import React, { Component } from "react";
import { PropTypes } from "prop-types";

// Redux
import { connect } from "react-redux";
import { verifyEmail } from "../redux/actions/userAction";
import { logoutUser } from "./../redux/actions/userAction";

// MUI - Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = theme => ({
  submit: {
    marginTop: "20",
    position: "relative"
  },
  customError: {
    color: theme.palette.error,
    fontSize: "0.8rem",
    textAlign: "left",
    margin: "20px auto 20px auto"
  },
  progress: {
    position: "absolute"
  },
  checkBox: {
    float: "left",
    textAlign: "left"
  }
});

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: false,
      errors: {}
    };
  }

  // TODO: Need to complete this process
  handleSumbit = event => {
    event.preventDefault();
    this.props.verifyEmail(this.props.history);
    this.setState({ emailSent: true });
    this.props.logoutUser();
  };

  // componentWillUnmount() {
  //   alert(
  //     "Verification Email has been sent!\nSign Back In Once You Verify Your Account!\nThank You <3"
  //   );
  // }

  render() {
    const {
      classes,
      UI: { loading }
    } = this.props;
    const { emailSent } = this.state;
    let messgae;
    if (emailSent) {
      messgae = EmailSent();
    } else {
      messgae = EmailNotSent();
    }
    return (
      <Container component="main" maxWidth="xs">
        <div className="email_verify" style={{ textAlign: "center" }}>
          {messgae}
          {!emailSent && (
            <div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleSumbit}
                disabled={loading}
              >
                Send Verification Email
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

const EmailSent = () => {
  return (
    <div>
      <h1>adaptMi</h1>
      <h3>Please Check your Mail box </h3>
      <h3>The Verification Email has been sent</h3>
      <h4>Make Take serveral Minutes</h4>
    </div>
  );
};

const EmailNotSent = () => {
  return (
    <div>
      <h1>adaptMi</h1>
      <h3>Looks Like You Still Need To Verify Your Email</h3>
      <h3>Please Check Your Mail Box!</h3>
    </div>
  );
};

VerifyEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  verifyEmail,
  logoutUser
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(useStyles)(VerifyEmail));
