import React, { Component } from "react";
import { PropTypes } from "prop-types";
import {
  Page,
  Text,
  //View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import firebase from "../util/config";
//import ReactPDF from "@react-pdf/renderer";

//import firebase from "./../util/config";

// Redux
import { connect } from "react-redux";
// import { getQuestions } from "../redux/actions/dataAction";
import {
  //addNote,
  getGroupInfo,
  //createGroupChatbubble,
} from "../redux/actions/userAction";

// Material UI
// import CreateIcon from "@material-ui/icons/Create";
// import ExploreIcon from "@material-ui/icons/Explore";
// import PersonAddIcon from "@material-ui/icons/PersonAdd";
// import { Divider } from "@material-ui/core";

const styles = StyleSheet.create({
  page: {
    textAlign: "center",
    marginTop: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    color: "#000000",
  },
});

//const arr = ["fun", "stuff", "here"];

// Create Document Component

// ReactPDF.render(<MyDocument />, `/example.pdf`);

class ChatHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [],
      readError: null,
      groupId: null,
      unsubscribe: null,
      id: this.props.groupId,
    };
    this.getChatForPdf = this.getChatForPdf.bind(this);
  }

  componentDidMount() {
    //this.setState({ groupId: this.props.currentGroup.id });
    this.getChatForPdf(this.state.id);
  }

  getChatForPdf(id) {
    if (id !== null) {
      let ref = firebase
        .firestore()
        .collection("groups")
        .doc(id)
        .collection("Chat")
        .orderBy("createdAt", "asc")
        .get((chat) => {
          this.setState({ chats: chat });
        });
    }
  }

  render() {
    const { chat } = this.props;

    const MyDoc = (props) => (
      <Document>
        {this.getChatForPdf(this.props.groupId)}
        <Page>
          <Text style={styles.page} fixed>
            {this.props.groupName}
          </Text>
          {/* {chat &&
            chat.map((item) => (
              <Text>
                {item.bubble.who}:- {item.bubble.message}
              </Text>
            ))} */}

          {this.state.chats.length > 0 ? (
            this.state.chats.map((item) => (
              <Text>
                {item.bubble.who}:- {item.bubble.message}
              </Text>
            ))
          ) : (
            // <div>Loading...</div>
            // <img src={animation} alt="loading..." />
            // <div style={{marginBottom: 100, bottom: 50}}><LoadingPage chat={true}/></div>
            <Text>poop</Text>
          )}
        </Page>
      </Document>
    );

    return (
      <div>
        <PDFDownloadLink document={MyDoc()} fileName="ChatHistory.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download now!"
          }
        </PDFDownloadLink>
      </div>
    );
  }
}

ChatHistory.propTypes = {
  UI: PropTypes.object.isRequired,
  addFriendF: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  User: PropTypes.object.isRequired,
  addNote: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  Groups: PropTypes.object.isRequired,
  createGroupChatbubble: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
  currentGroup: state.user.currentGroup,
  chat: state.user.chat,
  UI: state.UI,
  Groups: state.user.groups,
  User: state.user.credentials,
});

export default connect(mapStateToProps, getGroupInfo)(ChatHistory);
