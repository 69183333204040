import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
// import AddIcon from "@material-ui/icons/Add";
// import IconButton from "@material-ui/core/IconButton";
// import Badge from "@material-ui/core/Badge";
// import { Tooltip } from "@material-ui/core";
import "../App.css";

// Redux items
import { connect } from "react-redux";
import { addFriend } from "../redux/actions/userAction";
import PropTypes from "prop-types";

// Import languages
import languageWords  from '../Languages';

//Component
// import PopupBox from '../Component/PopupBox';
// import { LoadingPage } from './../util/LoadingPage';
// import AskQuestion from "../Component/AskQuestion";
import { getGroupList } from "../redux/actions/dataAction";

const useStyles = makeStyles({
  list: {
    width: 250,
    height: "100%",
  },
  fullList: {
    width: "auto",
  },
  outer: {
    height: "100%",
    paddingBottom: 155,
  },
});

function GroupTabs(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    left: false,
  });
  // React.useEffect(() => {
  //   console.log(props.user.handle)
  //   let ref = props.getGroupList(props.user.handle)
  //   return () => {
  //     ref();
  //   };
  // }, );
  const currentGroup = props.currentGroup;
  if( props.user.handle !== null && props.groups.length === 0){
    props.getGroupList(props.user.handle);
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  function handleGroupClick(event) {
    props.handleGroup(event);
  }

  const list = (anchor) => (
    

    <div className={classes.outer}>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ textAlign: "center", marginRight: 5 }}>{languageWords.groupLabel}</h1>

          {/* <Badge
            overlap="circle"
            style={{ position: "absolute", right: 40 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <Tooltip title="Find New Group" placement="top">
                <IconButton>
                  <AddIcon onClick={() => props.togglePopup()} />
                </IconButton>
              </Tooltip>
            }
          ></Badge> */}
        </div>
        <Divider />

        <div style={{ height: "100%", overflowY: "auto" }} class="scroll">
          <List style={{ height: "40vh", position: "relative" }}>
            <ListItem
              button
              onClick={() => {
                handleGroupClick("notes");
              }}
            >
              <ListItemText primary= {languageWords.Notes} />
            </ListItem>
            {props.groups &&
              props.groups.map((group) => (
                <ListItem
                  button
                  selected={currentGroup === group.id ? true : false}
                  key={group.name}
                  onClick={() => {
                    handleGroupClick(group.id);
                  }}
                >
                  <ListItemText primary={group.name} />
                </ListItem>
              ))}
          </List>
        </div>

        <Divider />
        <div>
          {/* <List style={{ marginTop: 6 }}>
            <ListItem
              button
              onClick={() => {
                handleGroupClick("notes");
              }}
            >
              <ListItemText style={{ textAlign: "center" }}>
                {languageWords.MyNotes}
              </ListItemText>
            </ListItem>
          </List> */}

          <Button
            variant="contained"
            color="primary"
            onClick={() => props.togglePopup()}
            style={{
              marginTop: 18,
              marginLeft: 42,
              marginRight: "auto",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {languageWords.findNewGroup}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <Button onClick={toggleDrawer("left", true)}>
          <MenuIcon />
        </Button>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

GroupTabs.propTypes = {
  addFriend: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  UI: PropTypes.object.isRequired,
  handleGroup: PropTypes.func.isRequired,
  currentGroup: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  groups: state.data.groups,
  user: state.user.credentials,
  UI: state.UI,
});

export default connect(mapStateToProps, { addFriend, getGroupList })(GroupTabs);
