import { SET_QUESTIONS, 
  SET_NEWQUESTIONS, 
  LOADING_CLEAR, 
  LOADING_DATA, 
  ADD_GROUP, 
  REMOVE_LISTENER,
  REMOVE_GROUP,
  LISTENER_ADD,
} from "./../types";

const initialState = {
  firstQuestion: {},
  secondQuestion: {},
  loading: false,
  groups: [],
  ref: null
};

export default function(state = initialState, action) {
  let group = state.groups;
  switch (action.type) {
    case SET_QUESTIONS:
      if (action.number === "First")
        return {
          ...state,
          firstQuestion: action.payload
        };
      if (action.number === "Second")
        return {
          ...state,
          secondQuestion: action.payload
        };
      break;
    case ADD_GROUP:
      group.push(action.payload)
      return { 
        ...state,
        groups: group
      };
    case REMOVE_GROUP:
      group =  removeElement(group, action.payload.id);
      return { 
        ...state,
        groups: group
      };
    case LOADING_DATA:
      return{
        ...state,
        loading: true
      }
    case LOADING_CLEAR:
      if(isEmpty(state.firstQuestion) || isEmpty(state.secondQuestion)) {
        return{
          ...state,
        }
      } else {
        return{
          ...state,
          loading: false
        }
      }
    case LISTENER_ADD: 
      return {
        ...state,
        ref: action.payload
      };
    case REMOVE_LISTENER: 
      if(state.ref !== null)state.ref();
      return { 
        ...state,
        ref: null,
        groups: [],
      }
    case SET_NEWQUESTIONS:
      return {
        ...state,
        firstQuestion: {},
        secondQuestion: {},
      };
    default:
      return state;
  }
}

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

function removeElement(array, elm){

  let index = -1;

  for(var i = 0;  i < array.length; i++){
    if(array[i].id === elm){
      index = i;
      break;
    }
  }
  if (index > -1) {
    array.splice(index, 1);
  }
  return array
}