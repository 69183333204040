import React from "react";
import fast from "../Images/logofast.gif"

export function LoadingPage (props) {

  let pageLoad = props.chat ? <img src={fast} alt="loading page" style={{width: 50, height: 50, zIndex: 50}}/> : 
  <div className="loader-wrapper">
  {/* //   <span class="loader">
  //     <span class="loader-inner"></span>
  //   </span> */}
    <img src={fast} alt="loading page" style={{width: 80, height: 80, zIndex: 50}}/>
  </div>

  return (
    <div>{pageLoad} </div>
  );
}

export default LoadingPage;
