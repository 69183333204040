import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setTheme } from "../redux/actions/userAction";

const state = { checkedA: true };

class ThemeSwitch extends Component {
  constructor() {
    super();
    this.state = {};
  }

  handleChange = name => event => {};

  handleTheme = event => {
    this.props.setTheme(this.props.theme);
  };

  render() {
    return (
      <div>
        <Switch
          checked={state.checkedB}
          onChange={this.handleChange("checkedB")} //handleChange("checkedB")
          onClick={this.handleTheme}
          value="checkedB"
          color="#000000"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
    );
  }
}

ThemeSwitch.propTypes = {
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  theme: state.theme
});

const mapActionsToProps = {
  setTheme
};

export default connect(mapStateToProps, mapActionsToProps)(ThemeSwitch);

// onClick={() => { func1(); func2();}}
