import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBjVhOueCn8Y-gUZ3LevShtzWbXzSiVMS8",
  authDomain: "adaptmi-25b0b.firebaseapp.com",
  databaseURL: "https://adaptmi-25b0b.firebaseio.com",
  projectId: "adaptmi-25b0b",
  storageBucket: "adaptmi-25b0b.appspot.com",
  messagingSenderId: "562606052727",
  appId: "1:562606052727:web:633adb98e8dc4e611f3d72",
  measurementId: "G-KE7XL9NRPT",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
